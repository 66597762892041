import { useEffect } from 'react';
import { Grid } from '@mui/material';
import AutocompleteComponent from '../../../components/layout/Autocomplete';
import { useTranslation } from 'react-i18next';
import useContracts from '../../../components/layout/ContractSelector/hook/useContracts';
import {
    useGetEmpresasQuery,
    useLazyGetCitiesQuery,
    useGetProvincesQuery
} from '../../../services/companyApi';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useGetTiposVisitasQuery } from '../../../services/visitasApi';
import { Dispatch, SetStateAction } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { VisitStatus } from '../consts/visitasStatus';

interface VisitasFiltersProps {
    extraFilters: any;
    setExtraFilters: Dispatch<SetStateAction<any>>;
    handleChangeExtraFilters: (e: any) => void;
    isShowMoreFiltersOpen?: boolean;
}

const VisitasFilters = ({
    extraFilters = {},
    handleChangeExtraFilters,
    isShowMoreFiltersOpen = false
}: VisitasFiltersProps) => {
    const { t } = useTranslation('visitas');
    const { contracts } = useContracts();

    const filters = extraFilters || {};
    const idOrganismoCentral = filters?.idOrganismoCentral?.id;

    const contractOptions = contracts.map((contract) => ({
        nombre: `${contract.nroContrato} - ${contract.nombre}`,
        id: contract.id,
        value: contract.nroContrato
    }));

    const { data: establishmentsData } = useGetEmpresasQuery(
        { idOrganismoCentral, skip: 0, take: 1000 },
        { skip: !idOrganismoCentral }
    );

    useEffect(() => {
        handleChangeExtraFilters({
            target: {
                name: 'idEstablecimiento',
                value: '',
                id: ''
            }
        });
    }, [extraFilters?.idOrganismoCentral]);

    const establishmentOptions =
        establishmentsData?.data?.data?.map((est: any) => ({
            id: est.id,
            nombre: est.nombre,
            value: est.id
        })) || [];

    const { data: provincesList } = useGetProvincesQuery(null);
    const provinces =
        provincesList?.data?.provincias?.map((province: { id: number; nombre: string }) => ({
            nombre: province.nombre,
            id: province.id,
            value: province.id
        })) || [];

    const [getCities, { data: citiesList }] = useLazyGetCitiesQuery();

    useEffect(() => {
        if (filters?.idProvincia?.value) {
            getCities({ provinceId: filters.idProvincia.value });
        }
    }, [filters.idProvincia]);

    const cities = Array.isArray(citiesList?.data?.localidades)
        ? citiesList.data.localidades.map(
              (city: { id: number; nombre: string; codigoPostal: number }) => ({
                  nombre: `${city.nombre} - ${city.codigoPostal}`,
                  id: city.id,
                  value: city.id
              })
          )
        : [];

    useEffect(() => {
        handleChangeExtraFilters({
            target: {
                name: 'idEstablecimiento',
                value: '',
                id: ''
            }
        });
    }, [extraFilters?.idOrganismoCentral]);

    const { data: tiposVisitasData } = useGetTiposVisitasQuery(null);

    const tipoVisitaOptions =
        tiposVisitasData?.data?.tipos?.map((tipo: any) => ({
            id: tipo.id,
            nombre: tipo.descripcion,
            value: tipo.id
        })) || [];

    const estadoOptions = Object.entries(VisitStatus).map(([key, value]) => ({
        id: Number(key),
        nombre: value,
        value: Number(key)
    }));

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
                <AutocompleteComponent
                    label={t('filters.contract')}
                    size="small"
                    name="idOrganismoCentral"
                    onChange={(value: any) =>
                        handleChangeExtraFilters({ target: { name: 'idOrganismoCentral', value } })
                    }
                    value={filters?.idOrganismoCentral || null}
                    options={contractOptions}
                    clearable
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AutocompleteComponent
                    label={t('filters.establishment')}
                    size="small"
                    name="idEstablecimiento"
                    onChange={(value: any) =>
                        handleChangeExtraFilters({ target: { name: 'idEstablecimiento', value } })
                    }
                    value={filters?.idEstablecimiento || null}
                    options={establishmentOptions}
                    clearable
                    disabled={!filters?.idOrganismoCentral}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AutocompleteComponent
                    label={t('filters.province')}
                    size="small"
                    name="idProvincia"
                    onChange={(value: any) =>
                        handleChangeExtraFilters({ target: { name: 'idProvincia', value } })
                    }
                    value={filters?.idProvincia || null}
                    options={provinces}
                    clearable
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AutocompleteComponent
                    label={t('filters.city')}
                    size="small"
                    name="idCiudad"
                    onChange={(value: any) =>
                        handleChangeExtraFilters({ target: { name: 'idCiudad', value } })
                    }
                    value={filters?.idCiudad || null}
                    options={cities}
                    clearable
                    templateLabel={(option: any) => option?.nombre}
                    disabled={!filters?.idProvincia}
                />
            </Grid>

            {isShowMoreFiltersOpen && (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t('filters.visitDate')}
                                value={filters?.fechaVisita || null}
                                onChange={(newDate) =>
                                    handleChangeExtraFilters({
                                        target: { name: 'fechaVisita', value: newDate }
                                    })
                                }
                                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                format="DD/MM/YYYY"
                                disableFuture
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <AutocompleteComponent
                            label={t('filters.state')}
                            size="small"
                            name="estado"
                            onChange={(value: any) =>
                                handleChangeExtraFilters({ target: { name: 'estado', value } })
                            }
                            value={filters?.estado || null}
                            options={estadoOptions}
                            clearable
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <AutocompleteComponent
                            label={t('filters.typeVisit')}
                            size="small"
                            name="idTipoVisita"
                            onChange={(value: any) =>
                                handleChangeExtraFilters({
                                    target: { name: 'idTipoVisita', value }
                                })
                            }
                            value={filters?.idTipoVisita || null}
                            options={tipoVisitaOptions}
                            clearable
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default VisitasFilters;
