import _ from 'lodash';
import { useGetEmpresaQuery } from '../../../services/companyApi';


const useEstablecimientoPresentaciones = (idEstablecimiento?: number) => {
    const {
        data: companyById,
        isLoading: loadingCompanyById,
        isFetching: fetchingCompanyById
    } = useGetEmpresaQuery(idEstablecimiento);
    
  
    if (!idEstablecimiento) {
      return {
        companyById: null,
        fetchingCompanyById,
        loadingCompanyById
      };
    }
  
    return {
      companyById,
      fetchingCompanyById,
      loadingCompanyById
    };
  };
  
  export default useEstablecimientoPresentaciones;


