import React, { useCallback, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/functions';
import { useTranslation } from 'react-i18next';

type PageTitleComponentProps = {
    displayTitleHeader: string;
    pageTitle: string;
};

const PageTitle: React.FC<PageTitleComponentProps> = ({ displayTitleHeader, pageTitle }) => {
    const location = useLocation();
    const { t } = useTranslation('layoutT');
    const pathnames = location.pathname.split('/').filter((x) => x);
    const lastSegment = pathnames[pathnames.length - 1] || '';

    const checklastSegmentTitleValue = useCallback(() => {
        if (
            pathnames.includes('establecimiento') &&
            pathnames.includes('presentaciones') &&
            (pathnames.includes('editar-rar') || pathnames.includes('visualizar-rar'))
        ) {
            return 'Detalle Nómina';
        }
        return formatSegmentTitle(lastSegment);
    }, [pathnames, lastSegment]);

    const formatSegmentTitle = (segment: string) => {
        if (!segment) return '';

            const specialTerms = ['rgrl', 'rar'];

            const customTitles: Record<string, string> = {
                ejecucion: t('pages.ejecucion'),
            };

            if (customTitles[segment.toLowerCase()]) {
                return customTitles[segment.toLowerCase()];
            }

            const words = segment.split('-').map((word) => {
            return specialTerms.includes(word.toLowerCase())
                ? word.toUpperCase()
                : capitalizeFirstLetter(word);
        });

        return words.join(' ');
    };

    useEffect(() => {
        const newTitle =
            checklastSegmentTitleValue() || displayTitleHeader || pageTitle || t('pages.init');
        document.title = `${newTitle}`;
    }, [checklastSegmentTitleValue, displayTitleHeader, pageTitle, t]);

    return (
        <>
            {lastSegment.toLowerCase() !== 'home' && (
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: '18px',
                        fontWeight: '700',
                        color: '#1E272E'
                    }}
                >
                    {checklastSegmentTitleValue()}
                </Typography>
            )}

            <Typography
                variant="h6"
                sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    color: '#1E272E',
                    textTransform: 'capitalize'
                }}
            >
                {displayTitleHeader}
            </Typography>
        </>
    );
};

export default PageTitle;
