export const Arrow360 = (props: any) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1756_54888)">
                <path
                    d="M12 7C6.48 7 2 9.24 2 12C2 14.24 4.94 16.13 9 16.77V18.79C9 19.24 9.54 19.46 9.85 19.14L12.64 16.35C12.84 16.15 12.84 15.84 12.64 15.64L9.85 12.85C9.54 12.54 9 12.76 9 13.21V14.73C5.85 14.17 4 12.83 4 12C4 10.94 7.04 9 12 9C16.96 9 20 10.94 20 12C20 12.66 18.8 13.68 16.68 14.34C16.27 14.47 16 14.85 16 15.28C16 15.95 16.65 16.44 17.28 16.24C20.11 15.36 22 13.79 22 12C22 9.24 17.52 7 12 7Z"
                    fill="#323232"
                />
            </g>
            <defs>
                <clipPath id="clip0_1756_54888">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const DockingIcon = (props: any) => {
    return (
        <svg
            {...props}
            width={props?.width || '25'}
            height={props?.height || '20'}
            viewBox="0 0 23 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.43839 0C7.3287 0 8.04799 0.692169 8.04799 1.54891V3.09782H14.4864V1.54891C14.4864 0.692169 15.2057 0 16.096 0C16.9863 0 17.7056 0.692169 17.7056 1.54891V3.09782H20.12C21.4529 3.09782 22.5344 4.1385 22.5344 5.42119V7.74455H0V5.42119C0 4.1385 1.08145 3.09782 2.4144 3.09782H4.82879V1.54891C4.82879 0.692169 5.54808 0 6.43839 0ZM0 9.29346H22.5344V22.4592C22.5344 23.7419 21.4529 24.7826 20.12 24.7826H2.4144C1.08145 24.7826 0 23.7419 0 22.4592V9.29346ZM16.5487 14.7631C17.0215 14.3081 17.0215 13.5723 16.5487 13.1222C16.0759 12.672 15.3113 12.6672 14.8435 13.1222L10.065 17.7205L7.70092 15.4455C7.2281 14.9906 6.46354 14.9906 5.99575 15.4455C5.52796 15.9005 5.52293 16.6363 5.99575 17.0864L9.21494 20.1842C9.68776 20.6392 10.4523 20.6392 10.9201 20.1842L16.5487 14.7631Z"
                fill="currentColor"
            />
        </svg>
    );
};

export const UserCrown = () => {
    return (
        <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 12.375C13.125 12.375 15.7143 9.92578 15.7143 6.875V0L12.8571 1.375L10 0L7.14286 1.375L4.28571 0V6.875C4.28571 9.92578 6.83036 12.375 10 12.375ZM6.42857 5.5H13.5714V6.875C13.5714 8.80859 11.9643 10.3125 10 10.3125C7.99107 10.3125 6.42857 8.80859 6.42857 6.875V5.5ZM13.9732 13.0625C13.4821 13.0625 12.9911 13.1914 12.5446 13.3633C11.7411 13.6211 10.8929 13.75 10 13.75C9.0625 13.75 8.21429 13.6211 7.41071 13.3633C6.96429 13.1914 6.47321 13.0625 5.98214 13.0625C2.67857 13.0625 0 15.6836 0 18.8633V19.9375C0 21.0977 0.9375 22 2.14286 22H17.8571C19.0179 22 20 21.0977 20 19.9375V18.8633C20 15.6836 17.2768 13.0625 13.9732 13.0625ZM17.8571 19.9375H2.14286V18.8633C2.14286 16.8008 3.83929 15.125 5.98214 15.125C6.16071 15.125 6.38393 15.2109 6.69643 15.2969C7.76786 15.6406 8.88393 15.8125 10 15.8125C11.0714 15.8125 12.1875 15.6406 13.2589 15.2969C13.5714 15.2109 13.7946 15.125 13.9732 15.125C16.1161 15.125 17.8571 16.8008 17.8571 18.8633V19.9375Z"
                fill="#262626"
            />
        </svg>
    );
};

export const DownloadFileIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill="#FFFFFF"
    >
        <path d="m720-120 160-160-56-56-64 64v-167h-80v167l-64-64-56 56 160 160ZM560 0v-80h320V0H560ZM240-160q-33 0-56.5-23.5T160-240v-560q0-33 23.5-56.5T240-880h280l240 240v121h-80v-81H480v-200H240v560h240v80H240Zm0-80v-560 560Z" />
    </svg>
);

