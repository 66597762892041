import { useState, useEffect } from 'react';
import { useGetCentralOrganismsQuery } from '../../../../services/organismsApi';

interface Contract {
    id: number;
    nombre: string;
    nroContrato: number;
}

const useContracts = () => {
    const { data, error, isLoading, isFetching } = useGetCentralOrganismsQuery(null);
    const [contracts, setContracts] = useState<Contract[]>([]);

    useEffect(() => {
        if (data && data.data && data.data.organismosCentral) {
            const formattedContracts = data.data.organismosCentral.map((item: any) => ({
                id: item.id,
                nombre: item.nombre,
                nroContrato: item.nroContrato
            }));

            setContracts(formattedContracts);
        }
    }, [data]);

    return { contracts, loading: isLoading, isFetching, error };
};

export default useContracts;
