import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useAppSelector } from '../hooks/reduxHooks';
import { blueTheme, greenTheme } from '../theme/theme';
import { useRoles } from '../hooks/useRoles';

interface ThemeContextProps {
    theme: any;
    setTheme: (theme: any) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }
    return context;
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const [theme, setTheme] = useState(greenTheme);
    const userData = useAppSelector((state) => state.auth.user);
    const { isAdminBO, isSupervisorSIMP, isOperatorSIMP, isViewerBO, isRRHHSIMP, isPreventor } =
        useRoles();

    const updateCssVariables = (theme: any) => {
        document.documentElement.style.setProperty('--primary', theme.palette.primary.main);
    };

    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme === 'blue') {
            setTheme(greenTheme);
            document.title = 'BO';
        } else {
            setTheme(greenTheme);
            document.title = 'SIMP';
        }
    }, []);

    useEffect(() => {
        if (isSupervisorSIMP || isOperatorSIMP || isViewerBO || isRRHHSIMP) {
            setTheme(greenTheme);
            document.title = 'SIMP';
        } else if (isAdminBO || isPreventor) {
            setTheme(blueTheme);
            document.title = 'BO';
        }
    }, [userData]);

    useEffect(() => {
        updateCssVariables(theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
