import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell
} from '@mui/material';
import { useAppSelector } from '../../hooks/reduxHooks';
import DataTableRow from './DataTableRow';
import Loading from '../Loading';
import NoInfo from '../NoInfo';
import styles from './styles.module.css';
import { useIsMobile } from '../../hooks/useIsMobile';

const MainTable = (props: any) => {
    const {
        items,
        children,
        isFetching,
        style,
        headers,
        columnTitles,
        columnWidths,
        noInfoTitle,
        noInfoSubtitle,
        testID,
        noInfoText = "noItems"
    } = props;
    const { isMobile } = useIsMobile();
    const { loading } = useAppSelector((state) => state.application);

    return (
        <Box sx={style || null}>
            <Paper>
                 <TableContainer sx={{ maxHeight: '746px', overflowX: 'auto' }}>
                    <Table
                    data-testid={testID || "table"}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        className={styles['datatable']}
                    >
                        <TableHead>
                            <TableRow
                                style={{
                                    height: '50px',
                                    color: 'white',
                                    textAlign: 'left',
                                    paddingLeft: 20
                                }}
                            >
                                {columnTitles.map((title: string, index: number) => {
                                    const isLastColumn = index === columnTitles.length - 1;
                                        return (
                                            <TableCell
                                            key={index}
                                            style={{
                                                backgroundColor: '#384A52',
                                                color: 'white',
                                                width: columnWidths[index],
                                                paddingLeft: 20,
                                                textAlign: 'left',
                                                position: isMobile && isLastColumn ? 'sticky' : 'static',
                                                right: isMobile && isLastColumn ? 0 : undefined,
                                                zIndex: isMobile && isLastColumn ? 2 : undefined
                                            }}
                                            >
                                            {title}
                                            </TableCell>
                                        );
                                    })}
                            </TableRow>
                        </TableHead>
                        <TableBody className={styles['datatable-tbody']}>
                            {isFetching || loading ? (
                                <TableRow>
                                    <TableCell colSpan={headers?.length}>
                                        <Loading size="small" />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                items?.map((item: any, index: number) => (
                                    <DataTableRow
                                        key={item?.id || index}
                                        item={item}
                                        headers={headers}
                                        isMobile={isMobile}
                                        {...props}
                                    />
                                ))
                            )}
                        </TableBody>
                    </Table>
                    {items === undefined && !loading && !isFetching && (
                        <NoInfo title={noInfoTitle} subtitle={noInfoSubtitle} />
                    )}
                    {items?.length < 1 && !loading && !isFetching && (
                        <NoInfo text={noInfoText} title={noInfoTitle} subtitle={noInfoSubtitle} />
                    )}
                </TableContainer>
            </Paper>
            <div>
                <span>{children}</span>
            </div>
        </Box>
    );
};

export default MainTable;
