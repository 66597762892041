import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SignaturePad from 'signature_pad';
import { Visita } from '../../consts/visitasTypes';
import {
    useGetTiposVisitasQuery,
    useLazyGetVisitasQuery,
    usePatchEjecutionVisitMutation
} from '../../../../services/visitasApi';
import { useSnackbar } from 'notistack';
import { showAlert } from '../../../../features/slices/applicationSlice';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { useLazyGetPresentacionesByEstablecimientoQuery } from '../../../../services/presentacionesApi';
import { useUpdatePresentationStateMutation } from '../../../../services/presentacionesApi';
import { setEstablishmentId } from '../../../../redux/slices/establishmentSlice';
import { VisitaEstado } from '../../consts/visitasStatus';

export const useEjecutionsVisits = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation('visitas');
    const [rgrlPresentaciones, setRgrlPresentaciones] = useState<any[]>([]);

    const [fetchVisitaById, { data: refreshedVisitas }] = useLazyGetVisitasQuery();

    const selectedVisitaDefault = useMemo(() => {
        return refreshedVisitas?.data?.data?.[0] || location.state?.selectedVisita || {};
    }, [refreshedVisitas, location.state?.selectedVisita]);

    const selectedVisita = refreshedVisitas?.data?.data?.[0] || selectedVisitaDefault;

    const [fetchPresentacionesByEstablecimiento, { data: presentaciones }] =
        useLazyGetPresentacionesByEstablecimientoQuery();
    const selectedEstablishmentId = selectedVisita?.idEstablecimiento;
    const { enqueueSnackbar } = useSnackbar();
    const [isFormValid, setIsFormValid] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedVisitaDetail, setSelectedVisitaDetail] = useState<Visita | null>(null);
    const [signatureErrors, setSignatureErrors] = useState({
        firmaPreventor: false,
        firmaResponsable: false
    });
    const idVisita = location.state?.selectedVisita?.id || {};
    const idEstablecimiento = location.state?.selectedVisita?.idEstablecimiento || {};
    const handleOpenDrawer = () => {
        setSelectedVisitaDetail(selectedVisita);
        setOpenDrawer(true);
    };
    const isVerosimilitudFinalizada = selectedVisita?.idEstado === VisitaEstado.REALIZADA;
    const [updatePresentationState] = useUpdatePresentationStateMutation();

    const [loadingPresentaciones, setLoadingPresentaciones] = useState(false);
    const [patchEjecutionVisit, { isLoading, isError }] = usePatchEjecutionVisitMutation();
    const [openDialogRGRL, setOpenDialogRGRL] = useState(false);
    const handleOpenDialogRGRL = () => setOpenDialogRGRL(true);
    const handleCloseDialogRGRL = () => setOpenDialogRGRL(false);
    const [formData, setFormData] = useState({
        esFallida: false,
        totalPersonal: null,
        administrativos: null,
        personalProduccion: null,
        seguridadHigiene: null,
        profesional: '',
        matricula: '',
        idTipoVisita: null,
        IdSubTipoVisita: null,
        observacion: '',
        firmaPreventor: '',
        firmaResponsable: '',
        estado: 2
    });

    const { data: tiposVisitas, isLoading: loadingTiposVisitas } = useGetTiposVisitasQuery(null);

    const tiposVisitasOptions = (tiposVisitas?.data?.tipos || []).map((tipo: any) => ({
        nombre: tipo.descripcion,
        id: tipo.id,
        value: tipo.id
    }));

    const subTypesVisitasOptions = (tiposVisitas?.data?.subtipos || []).map((tipo: any) => ({
        nombre: tipo.descripcion,
        id: tipo.id,
        value: tipo.id
    }));

    useEffect(() => {
        const isValidWhenFallida =
            formData.esFallida &&
            formData.observacion.trim() !== '' &&
            formData.firmaPreventor !== '';

        const isValidWhenNotFallida =
            !formData.esFallida &&
            formData.totalPersonal !== null &&
            formData.administrativos !== null &&
            formData.personalProduccion !== null &&
            formData.seguridadHigiene !== null &&
            (selectedVisita.nombreTipoVisita !== 'GENERICA' || formData.IdSubTipoVisita !== null) &&
            formData.observacion.trim() !== '' &&
            formData.firmaPreventor !== '' &&
            formData.firmaResponsable !== '';

        const isRgrlValid =
            selectedVisita?.nombreTipoVisita !== 'RGRL' ||
            rgrlPresentaciones.some((p) => [2, 3, 4, 5].includes(p.idEstado));

        setIsFormValid((isValidWhenFallida || isValidWhenNotFallida) && isRgrlValid);
    }, [formData, selectedVisita, rgrlPresentaciones]);

    const canvasPreventorRef = useRef<HTMLCanvasElement>(null);
    const canvasResponsableRef = useRef<HTMLCanvasElement>(null);
    const signaturePadPreventorRef = useRef<SignaturePad | null>(null);
    const signaturePadResponsableRef = useRef<SignaturePad | null>(null);

    useEffect(() => {
        if (formData.seguridadHigiene === false) {
            setFormData((prev) => ({
                ...prev,
                profesional: '',
                matricula: ''
            }));
        }
    }, [formData.seguridadHigiene]);

    const resizeCanvas = (
        canvasRef: React.RefObject<HTMLCanvasElement>,
        signaturePadRef: React.RefObject<SignaturePad | null>
    ) => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ratio = window.devicePixelRatio || 1;
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = 150 * ratio;
            const ctx = canvas.getContext('2d');
            ctx?.scale(ratio, ratio);
            signaturePadRef.current?.clear();
        }
    };

    useEffect(() => {
        if (canvasPreventorRef.current) {
            signaturePadPreventorRef.current = new SignaturePad(canvasPreventorRef.current);
            signaturePadPreventorRef.current.addEventListener('endStroke', saveSignaturePreventor);
            resizeCanvas(canvasPreventorRef, signaturePadPreventorRef);
        }
        if (canvasResponsableRef.current) {
            signaturePadResponsableRef.current = new SignaturePad(canvasResponsableRef.current);
            signaturePadResponsableRef.current.addEventListener(
                'endStroke',
                saveSignatureResponsable
            );
            resizeCanvas(canvasResponsableRef, signaturePadResponsableRef);
        }

        window.addEventListener('resize', () => {
            resizeCanvas(canvasPreventorRef, signaturePadPreventorRef);
            resizeCanvas(canvasResponsableRef, signaturePadResponsableRef);
        });

        return () => {
            window.removeEventListener('resize', () => {
                resizeCanvas(canvasPreventorRef, signaturePadPreventorRef);
                resizeCanvas(canvasResponsableRef, signaturePadResponsableRef);
            });
        };
    }, []);

    const saveSignaturePreventor = () => {
        if (signaturePadPreventorRef.current && !signaturePadPreventorRef.current.isEmpty()) {
            setFormData((prev) => ({
                ...prev,
                firmaPreventor: signaturePadPreventorRef.current!.toDataURL('image/png')
            }));
        }
    };

    const saveSignatureResponsable = () => {
        if (signaturePadResponsableRef.current && !signaturePadResponsableRef.current.isEmpty()) {
            setFormData((prev) => ({
                ...prev,
                firmaResponsable: signaturePadResponsableRef.current!.toDataURL('image/png')
            }));
        }
    };

    const handleClearSignature = (type: 'preventor' | 'responsable') => {
        if (type === 'preventor' && signaturePadPreventorRef.current) {
            signaturePadPreventorRef.current.clear();
            setFormData((prev) => ({ ...prev, firmaPreventor: '' }));
        } else if (type === 'responsable' && signaturePadResponsableRef.current) {
            signaturePadResponsableRef.current.clear();
            setFormData((prev) => ({ ...prev, firmaResponsable: '' }));
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: name === 'subtipoVisita' ? Number(value) : value
        }));
    };

    const handleFalloVisitaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;

        signaturePadPreventorRef.current?.clear();
        signaturePadResponsableRef.current?.clear();

        setFormData((prev) => ({
            ...prev,
            esFallida: checked,
            totalPersonal: checked ? null : prev.totalPersonal,
            administrativos: checked ? null : prev.administrativos,
            personalProduccion: checked ? null : prev.personalProduccion,
            seguridadHigiene: null,
            profesional: '',
            matricula: '',
            idTipoVisita: null,
            IdSubTipoVisita: null,
            observacion: checked ? prev.observacion : '',
            firmaPreventor: checked ? prev.firmaPreventor : '',
            firmaResponsable: '',
            estado: checked ? 3 : 2
        }));
    };

    const confirmSubmit = () => {
        const alertTitle = formData.esFallida
            ? t('execution.failedVisitConfirmTitle')
            : t('execution.confirmTitle');

        const alertMessage = formData.esFallida
            ? t('execution.failedVisitConfirmMessage')
            : t('execution.confirmMessage');

        dispatch(
            showAlert({
                icon: 'info',
                title: alertTitle,
                message: alertMessage,
                confirmText: t('execution.confirmAction'),
                cancelText: t('buttons.cancel'),
                itemData: true,
                confirmAction: handleSubmit
            })
        );
    };

    const handleSubmit = async () => {
        try {
            await patchEjecutionVisit({
                idVisita: selectedVisita.id,
                esFallida: formData.esFallida,
                totalPersonal: Number(formData.totalPersonal),
                administrativos: Number(formData.administrativos),
                personalProduccion: Number(formData.personalProduccion),
                seguridadHigiene: Boolean(formData.seguridadHigiene),
                profesional: formData.profesional || '',
                matricula: formData.matricula || '',
                idTipoVisita: selectedVisita.idTipoVisita || null,
                IdSubTipoVisita: formData.IdSubTipoVisita || null,
                observacion: formData.observacion || '',
                firmaPreventor: formData.firmaPreventor,
                firmaResponsable: formData.firmaResponsable,
                estado: formData.esFallida ? 3 : 2
            }).unwrap();

            enqueueSnackbar(t('execution.successSnackbar'), { variant: 'success' });
            navigate('/backoffice/visitas');
        } catch (error: any) {
            enqueueSnackbar(t('execution.errorSnackbar'), { variant: 'error' });
        }
    };

    const handleFetchPresentaciones = async () => {
        if (!idEstablecimiento) return;

        try {
            setLoadingPresentaciones(true);
            const response = await fetchPresentacionesByEstablecimiento({
                idEstablecimiento,
                take: 100,
                skip: 0
            }).unwrap();

            const rgrl = response?.data?.data.filter((p: any) => p.tipo?.nombre === 'RGRL');
            setRgrlPresentaciones(rgrl);
        } catch (error) {
        } finally {
            setLoadingPresentaciones(false);
        }
    };

    useEffect(() => {
        handleFetchPresentaciones();
    }, [location.key]);

    const handleCardClickRGRL = (idTipoPresentacion: number, titulo: string) => {
        dispatch(setEstablishmentId(idEstablecimiento));

        navigate('./crear-rgrl', {
            state: {
                tipoId: idTipoPresentacion,
                tipoTitulo: titulo
            }
        });
    };

    const handleOpenVerosimilitud = () => {
        const firstPresentation = rgrlPresentaciones[0];

        if (!firstPresentation) return;
        dispatch(setEstablishmentId(idEstablecimiento));

        navigate('./verosimilitud', {
            state: {
                selectedPresentationId: firstPresentation.id,
                selectedEstablishmentId: selectedEstablishmentId,
                tipoId: firstPresentation.tipo.id,
                idVisita: selectedVisita.id,
                idPreventor: selectedVisita.idPreventor,
                observacion: formData.observacion
            }
        });
    };

    useEffect(() => {
        if (selectedVisita?.id) {
            fetchVisitaById({ idVisita: selectedVisita.id, take: 10, skip: 0 });
        }
    }, [selectedVisita?.id]);

    const shouldShowVerosimilitudRow =
        selectedVisita?.idEstado === VisitaEstado.PENDIENTE &&
        Array.isArray(selectedVisita.verosimilitudRespuestas) &&
        selectedVisita.verosimilitudRespuestas.length === 0;

    const isVerosimilitudDraft =
        selectedVisita?.idEstado === VisitaEstado.PENDIENTE &&
        selectedVisita?.verosimilitudRespuestas?.length > 0;

    const confirmCancelPresentation = (idPresentation: number) => {
        dispatch(
            showAlert({
                icon: 'error',
                title: t('alerts.cancelPresentation.title'),
                message: t('alerts.cancelPresentation.message'),
                confirmText: t('alerts.cancelPresentation.confirmText'),
                cancelText: t('alerts.cancelPresentation.cancelText'),
                itemData: true,
                confirmAction: () => handleCancelPresentation(idPresentation)
            })
        );
    };

    const handleCancelPresentation = async (idPresentation: number) => {
        try {
            await updatePresentationState({
                presentationId: idPresentation,
                body: { idEstado: 6 }
            }).unwrap();

            enqueueSnackbar(t('alerts.cancelPresentation.success'), { variant: 'success' });
            handleFetchPresentaciones();
        } catch (error) {
            enqueueSnackbar(t('alerts.cancelPresentation.error'), { variant: 'error' });
        }
    };

    const allPresentationsCancelled =
        rgrlPresentaciones.length > 0 && rgrlPresentaciones.every((p: any) => p.idEstado === 6);

    const handleEditRGRL = (presentacion: any) => {
        if (!presentacion?.id) return;

        navigate('./editar', {
            state: {
                selectedPresentationId: presentacion.id,
                selectedEstablishmentId: presentacion.establecimiento.id,
                tipoId: presentacion.tipo.id,
                tipoTitulo: presentacion.tipo.nombre
            }
        });
    };

    return {
        formData,
        setFormData,
        isFormValid,
        handleChange,
        handleFalloVisitaChange,
        confirmSubmit,
        handleSubmit,
        handleClearSignature,
        openDrawer,
        setOpenDrawer,
        handleOpenDrawer,
        selectedVisita,
        selectedVisitaDetail,
        canvasPreventorRef,
        canvasResponsableRef,
        tiposVisitasOptions,
        subTypesVisitasOptions,
        loadingTiposVisitas,
        signatureErrors,
        rgrlPresentaciones,
        openDialogRGRL,
        handleOpenDialogRGRL,
        handleCloseDialogRGRL,
        handleCardClickRGRL,
        handleOpenVerosimilitud,
        loadingPresentaciones,
        shouldShowVerosimilitudRow,
        isVerosimilitudDraft,
        isVerosimilitudFinalizada,
        confirmCancelPresentation,
        allPresentationsCancelled,
        handleEditRGRL
    };
};
