import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { logout, tokenReceived } from '../features/auth/authSlice';

const BASE_URL = 'vinculacion-rgrl';

export interface GenerateFilesBody {
    idsPresentacion: number[]
}

export type SRT_RESPONSE_TXT_TYPE = 'RB' | 'RC' | 'RH' | 'RI' | 'RM' | 'RO' | 'RS'

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});
 
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();

        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const rgrlVinculationApi = createApi({
    reducerPath: 'rgrlVinculationApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Presentations', 'Contracts', 'Establishments'],
    endpoints: (builder) => ({
        getRGRLVinculationPresentations: builder.query({
            query: (params) => {
                return {
                    url: `${BASE_URL}/presentaciones`,
                    params: params
                };
            },
            providesTags: ['Presentations']
        }),
        getRGRLVinculationContracts: builder.query({
            query: () => ({
                url: `${BASE_URL}/contratos`
            }),
            providesTags: ['Contracts'],
            transformResponse: (response: any) => response?.data?.contratos
        }),
        getRGRLVinculationEstablishments: builder.query({
            query: () => ({
                url: `${BASE_URL}/establecimientos`
            }),
            providesTags: ['Establishments'],
            transformResponse: (response: any) => response?.data?.establecimientos
        }),
        generateFiles: builder.mutation({
            query: (body: GenerateFilesBody) => ({
                url: `${BASE_URL}/archivos`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['Presentations']
        }),
        uploadResponseSRT: builder.mutation({
            query: ({nameType, body }: {nameType: SRT_RESPONSE_TXT_TYPE, body: any}) => ({
                url: `${BASE_URL}/archivos/${nameType}`,
                method: 'POST',
                body: body
            })
        })
    })
});

export const {
    useGetRGRLVinculationPresentationsQuery,
    useGetRGRLVinculationContractsQuery,
    useGetRGRLVinculationEstablishmentsQuery,
    useGenerateFilesMutation,
    useUploadResponseSRTMutation
} = rgrlVinculationApi;
