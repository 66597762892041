const TEST_IDS = {
    main: {
        dialogOptions: {
            createNew: 'create-new',
            getBySRT: 'get-by-srt'
        },
        header: {
            new: 'header-new-establishment'
        },
        extraFilters: {
            idContrato: 'filter-contract',
            name: 'filter-name',
            city: 'filter-city',
            srtCode: 'filter-srtCode',
            organism: 'filter-organism',
            state: 'filter-state',
            rgrl: 'filter-rgrl',
            rar: 'filter-rar'
        },
        table: {
            id: 'establishments-table',
            actions: {
                seeDetails: 'table-actions-see-details',
                seePresentations: 'table-actions-seePresentations'
            }
        },
        information: {
            sections: {
                establishmentData: 'info-section-establishment-data',
                domicileData: 'info-section-domicile-data',
                additionalData: 'info-section-additional-data',
                establishmentStateData: 'info-section-establishment-state-data',
                presentations: 'info-section-presentations'
            },
            buttons: {
                editEstablishmentData: 'button-edit-establishment-data',
                editDomicileData: 'button-edit-domicile-data',
                editAdditionalData: 'button-edit-additional-data',
                deregister: 'button-deregister-establishment',
                deregisterRequest: 'button-deregister-request-establishment',
                checkDeregisterRequest: 'button-check-deregister-request',
                seePresentations: 'button-deregister-establishment',
                rejectEstablishment: 'button-reject-establishment',
                rejectPresentationRAR: 'button-reject-rar',
                rejectPresentationRGRL: 'button-reject-rgrl',
                managePayroll: 'button-manage-payroll'
            }
        },
        drawers: {
            edit: {
                data: {
                    warning: 'drawer-edit-alert-warning',
                    name: 'drawer-edit-input-data-name',
                    ciiu: 'drawer-edit-input-data-ciiu',
                    srtCode: 'drawer-edit-input-data-srtCode'
                },
                domicile: {
                    province: 'drawer-edit-domicile-province',
                    city: 'drawer-edit-domicile-city',
                    postalCode: 'drawer-edit-domicile-postalCode',
                    street: 'drawer-edit-domicile-street',
                    streetNumber: 'drawer-edit-domicile-streetNumber',
                    apartmentFloor: 'drawer-edit-domicile-apartment-floor',
                    apartmentNumber: 'drawer-edit-domicile-apartment-number',
                    latitude: 'drawer-edit-domicile-latitude',
                    longitude: 'drawer-edit-domicile-longitude'
                },
                additional: {
                    email: 'drawer-edit-additional-email',
                    phone: 'drawer-edit-additional-phone',
                    noPersonnelCheckbox: 'drawer-edit-additional-no-personnel-checkbox',
                    numberOfEmployees: 'drawer-edit-additional-number-of-employees',
                    observations: 'drawer-edit-additional-observations'
                }
            },
            deregisterRequest: {
                firstName: 'drawer-deregister-request-firstName',
                lastName: 'drawer-deregister-request-lastName',
                documentId: 'drawer-deregister-request-documentId',
                email: 'drawer-deregister-request-email',
                phone: 'drawer-deregister-request-phone',
                motive: 'drawer-deregister-request-motive'
            }
        }
    },
    establishmentSave: {
        sections: {
            establishmentData: 'section-establishment-data',
            domicileData: 'section-domicile-data',
            additionalData: 'section-additional-data'
        },
        inputs: {
            establishmentData: {
                organism: 'establishment-save-data-input-organism',
                name: 'establishment-save-data-input-name',
                ciiu: 'establishment-save-data-input-ciiu',
                srtCode: 'establishment-save-data-input-srt-code',
                ciiuRevisionLink: 'establishment-data-save-link-ciiu-revision-file'
            },
            domicileData: {
                province: 'establishment-save-domicile-input-province',
                city: 'establishment-save-domicile-input-city',
                postalCode: 'establishment-save-domicile-input-postal-code',
                street: 'establishment-save-domicile-input-street',
                streetNumber: 'establishment-save-domicile-input-street-number',
                aparmentFloor: 'establishment-save-domicile-input-aparment-floor',
                apartmentNumber: 'establishment-save-domicile-input-apartment-number',
                latitude: 'establishment-save-domicile-input-latitude',
                longitude: 'establishment-save-domicile-input-longitude'
            },
            additionalData: {
                email: 'establishment-save-additional-input-email',
                phone: 'establishment-save-additional-input-phone',
                employeeQuantity: 'establishment-save-additional-input-employee-quantity',
                noPersonnel: 'establishment-save-additional-input-noPersonnel',
                observations: 'establishment-save-additional-input-observations'
            }
        }
    },
    payrollAssignment: {
        filters: {
            firstName: 'filter-payroll-assignment-firstName',
            cuil: 'filter-payroll-assignment-cuil',
            buttons: {
                clearFilters: 'button-clear-filters',
                search: 'button-clear-search'
            }
        },

        tables: {
            contract: {
                testID: 'table-contract'
            },
            establishment: {
                testID: 'table-establishment',
                buttons: {
                    clearSelection: 'table-establishment-button-clear-selection',
                    save: 'table-establishment-button-save'
                }
            }
        }
    }
};

export { TEST_IDS };
