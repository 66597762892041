import React, { useState } from 'react';
import {
    Grid,
    Checkbox,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    TableContainer,
    TextField
} from '@mui/material';
import SectionFormBox from '../../../components/SectionFormBox/SectionFormBox';
import { useTranslation } from 'react-i18next';

interface Establishment {
    id: number;
    nombre: string;
    localidad: any;
    postalCode: string;
}

interface Props {
    establishments: Establishment[];
    selectedEstablishments: number[];
    handleSelectEstablishment: (id: number) => void;
    handleClearSelection: () => void;
}

const EstablecimientosSelector: React.FC<Props> = ({
    establishments,
    selectedEstablishments,
    handleSelectEstablishment,
    handleClearSelection
}) => {
    const { t } = useTranslation('visitas');

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredEstablishments =
        searchTerm.length >= 3
            ? establishments.filter((est) =>
                  est.nombre.toLowerCase().includes(searchTerm.toLowerCase())
              )
            : establishments;

    return (
        <>
            <SectionFormBox className="2" title={t('newVisit.establishmentInfo')}>
                <Grid sx={{ mb: 2 }} item xs={12} sm={6} md={12}>
                    <TextField
                        label={t('filters.searchEstablishment')}
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder={t('filters.searchTerm') ?? ''}
                    />
                </Grid>
            </SectionFormBox>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Paper elevation={3}>
                        <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{t('table.columnsTitles.nro')}</TableCell>
                                        <TableCell>
                                            {t('table.columnsTitles.establishment')}
                                        </TableCell>
                                        <TableCell>
                                            {t('table.columnsTitles.city')} -{' '}
                                            {t('table.columnsTitles.postalCode')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredEstablishments.length > 0 ? (
                                        filteredEstablishments.map((est: any) => (
                                            <TableRow key={est.id}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selectedEstablishments.includes(
                                                            est.id
                                                        )}
                                                        onChange={() =>
                                                            handleSelectEstablishment(est.id)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>{est.id}</TableCell>
                                                <TableCell>{est.nombre}</TableCell>
                                                <TableCell>
                                                    {est?.localidad?.nombre ||
                                                        est?.localidad ||
                                                        'N/A'}{' '}
                                                    -{' '}
                                                    {est?.localidad?.codigoPostal ||
                                                        est?.postalCode ||
                                                        'N/A'}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography
                                                    variant="body2"
                                                    align="center"
                                                    sx={{ py: 2 }}
                                                >
                                                    {t('table.noData.noEstablishmentsWithFilters')}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                <Grid item xs={5}>
                    <Paper elevation={3}>
                        <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{t('table.columnsTitles.nro')}</TableCell>
                                        <TableCell>
                                            {t('table.columnsTitles.establishment')}
                                        </TableCell>
                                        <TableCell>
                                            {t('table.columnsTitles.city')} -{' '}
                                            {t('table.columnsTitles.postalCode')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {establishments
                                        .filter((est) => selectedEstablishments.includes(est.id))
                                        .map((est) => (
                                            <TableRow key={est.id}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked
                                                        onChange={() =>
                                                            handleSelectEstablishment(est.id)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>{est.id}</TableCell>
                                                <TableCell>{est.nombre}</TableCell>
                                                <TableCell>
                                                    {est?.localidad?.nombre ||
                                                        est?.localidad ||
                                                        'N/A'}{' '}
                                                    -{' '}
                                                    {est?.localidad?.codigoPostal ||
                                                        est?.postalCode ||
                                                        'N/A'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ p: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClearSelection}
                                disabled={selectedEstablishments.length === 0}
                            >
                                {t('buttons.clean')}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default EstablecimientosSelector;
