import _ from 'lodash';
import { Box } from '@mui/material';
import { Fragment } from 'react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import MenuAccessItem from './components/MenuAccesItem';
import styles from './styles.menu.module.css';
import { useLocation } from 'react-router-dom';
import { useRoles } from '../../hooks/useRoles';
import { DownloadFileIcon } from '../Icons';


interface MenuProps {
    sideMenu?: boolean | undefined;
    handleDrawerToggle?: () => void;
    open?: boolean;
}

const HARCODED_HOME_ITEM = {
    id: 0,
    nombre: 'Inicio',
    funcionalidades: [
        {
            id: 15,
            nombre: 'home',
            urlRedireccion: '/home',
            visualizarMenu: true
        }
    ],
    orden: 4
};

const HARCODED_SRT_FILES_GENERATION_ITEM = {
    id: 9,
    nombre: 'Generación de archivos SRT',
    funcionalidades: [
        {
            id: 15,
            nombre: 'Generación de archivos SRT',
            urlRedireccion: '/gestion-de-archivos-para-la-SRT',
            visualizarMenu: true
        }
    ],
    orden: 10
};

const HARCODED_HELP_CENTER_ITEM = {
    id: 18,
    nombre: 'Centro de ayuda',
    funcionalidades: [
        {
            id: 15,
            nombre: 'Centro de ayuda',
            urlRedireccion: '/centro-de-ayuda',
            visualizarMenu: true
        }
    ],
    orden: 18
};



export const getIcon = (id: number, isSupervisorSIMP: boolean) => {
    const icons: { [key: number]: any } = {
        0: <HomeOutlinedIcon />,
        1: <AccountCircleOutlinedIcon />,
        2: <AccountCircleOutlinedIcon />,
        3: <LocationOnOutlinedIcon />,
        4: isSupervisorSIMP ? null : <TextSnippetOutlinedIcon />,
        7: <MapsHomeWorkOutlinedIcon />,
        5: <BadgeOutlinedIcon />,
        6: <WorkOutlineOutlinedIcon />,
        9: <DownloadFileIcon />,
        10: <EmojiTransportationOutlinedIcon />,
        18: <HelpOutlineOutlinedIcon />
    };
    return icons[id] || null;
};

function MenuComponent({ sideMenu, handleDrawerToggle, open }: MenuProps) {
    const { isViewerBO, isAdminBO} = useRoles();
    const location = useLocation();
    const userFunctionalities = useSelector(
        (state: RootState) => state.auth?.user?.funcionalidadesAgrupadas
    );

    const isActive = (item: any) =>
        item?.funcionalidades?.length > 0 &&
        item?.funcionalidades?.find((functionality: any) =>
            location.pathname.includes(functionality.urlRedireccion.split('/')[1])
        );

    return (
        <Box
            justifyContent={'space-between'}
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
        >
            <Box className={styles[`menucomponent-container${sideMenu ? '-side' : ''}`]}>
                <Fragment key={1000}>
                    <MenuAccessItem
                        item={HARCODED_HOME_ITEM}
                        sideMenu={sideMenu}
                        open={open}
                        handleDrawerToggle={handleDrawerToggle}
                        active={isActive(HARCODED_HOME_ITEM)}
                    />
                </Fragment>
                {userFunctionalities?.map((item: any, index: any) => {
                    return (
                        <Fragment key={index}>
                            <MenuAccessItem
                                item={item}
                                sideMenu={sideMenu}
                                open={open}
                                handleDrawerToggle={handleDrawerToggle}
                                active={isActive(item)}
                            />
                        </Fragment>
                    );
                })}
                {isAdminBO && (
                    <Fragment key={1001}>
                        <MenuAccessItem
                            item={HARCODED_SRT_FILES_GENERATION_ITEM}
                            sideMenu={sideMenu}
                            open={open}
                            handleDrawerToggle={handleDrawerToggle}
                            active={isActive(HARCODED_SRT_FILES_GENERATION_ITEM)}
                        />
                    </Fragment>
                )}
            </Box>
            <Box className={styles[`menucomponent-container${sideMenu ? '-side' : ''}`]}>
                {!isViewerBO && (
                    <MenuAccessItem
                        item={HARCODED_HELP_CENTER_ITEM}
                        sideMenu={sideMenu}
                        open={open}
                        handleDrawerToggle={handleDrawerToggle}
                        active={isActive(HARCODED_HELP_CENTER_ITEM)}
                    />
                )}
            </Box>
        </Box>
    );
}

export default MenuComponent;
