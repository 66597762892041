import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useEstablecimientoPresentaciones from '../../Rar/hooks/useEstablecimientoPresentaciones';
import Loading from '../../../components/Loading';
import BackdropComponent from '../../../components/Backdrop/BackdropComponent';
import { Field } from '../../Presentaciones/components/DynamicData';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { clearResponsables } from '../../../redux/slices/responsableSlice';
import { clearFields } from '../../../redux/slices/gremioContratistaSlice';
import useRGRLVerosimilitud from './hooks/useRGRLVerosimilitud';
import useRGRLVerosimilitudHandeler from './hooks/useRGRLVerosimilitudHandeler';
import VerosimilitudGrid from '../components/VerosimilitudGrid';
import ResponsableCargaSection from '../components/ResponsableCargaSection';

const RGRLVerosimilitud: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { t } = useTranslation('visitas');
    const [loading, setLoading] = useState<boolean>(false);
    const {
        selectedPresentationId,
        selectedEstablishmentId,
        tipoId,
        tipoTitulo: initialTipoTitulo,
        idVisita,
        idPreventor,
        observacion
    } = location.state || {};

    const responsables = useAppSelector((state) => state.responsable.responsables);
    const contratistas = useAppSelector((state) => state.gremioContratista.contratista);
    const gremio = useAppSelector((state) => state.gremioContratista.gremio);
    const [tipoTitulo, setTipoTitulo] = useState<string>(location.state?.tipoTitulo);

    const [fieldOrganismo, setFieldOrganismo] = useState<Field[]>([]);
    const [respuestas, setRespuestas] = useState<any[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const { companyById } = useEstablecimientoPresentaciones(selectedEstablishmentId);
    const selectedVisita = location?.state?.selectedVisita;

    const { responsePresentacion, error, isLoading } = useRGRLVerosimilitud(selectedPresentationId);

    const { handleExit, handleGuardarBorrador, handleEnviarPresentacion, isLoadingResponse } =
        useRGRLVerosimilitudHandeler(
            idVisita,
            tipoId,
            idPreventor,
            observacion,
            selectedPresentationId
        );

    useEffect(() => {
        return () => {
            dispatch(clearResponsables());
            dispatch(clearFields());
        };
    }, [dispatch]);

    useEffect(() => {
        setTipoTitulo(responsePresentacion?.titulo || tipoTitulo);
    }, [responsePresentacion]);

    useEffect(() => {
        if (companyById) {
            const establecimiento = companyById;
            const newFieldOrganismo: Field[] = [
                {
                    campo: t('headerRar.agencyName'),
                    valor: establecimiento?.organismo?.nombre || 'N/A'
                },
                {
                    campo: t('headerRar.agencyCuit'),
                    valor: establecimiento?.organismo?.cuit || 'N/A'
                },
                {
                    campo: t('headerRar.agencyContractNumber'),
                    valor: establecimiento?.organismo?.nroContrato || 'N/A'
                }
            ];
            setFieldOrganismo(newFieldOrganismo);
        }
    }, [companyById, t]);

    useEffect(() => {
        if (responsePresentacion) {
            localStorage.removeItem('verosimilitud');
            setIsInitialized(true);
        }
    }, [responsePresentacion]);

    const containerRef = useRef<HTMLDivElement>(null);

    if (isLoading) return <Loading />;
    if (isLoadingResponse) return <BackdropComponent loading={isLoadingResponse} />;

    const isEnviarDisabled = () => {
        return !responsables.some((responsable) => responsable.cargo === 1);
    };

    const handleGuardarBorradorAndNavigate = async () => {
        setLoading(true);
        await handleGuardarBorrador();
        setLoading(false);
    };

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                {responsePresentacion && isInitialized && (
                    <>
                        <ResponsableCargaSection responsables={responsePresentacion.responsables} />
                        <Box sx={{ pt: 5 }} ref={containerRef}>
                            <VerosimilitudGrid
                                secciones={responsePresentacion?.solapas[0].secciones || []}
                                setRespuestas={setRespuestas}
                                respuestas={respuestas}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button onClick={handleExit} variant="text">
                                    {t('verosimilitud.buttons.cancel')}
                                </Button>

                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button
                                    onClick={handleGuardarBorradorAndNavigate}
                                    variant="outlined"
                                    sx={{ mr: 1 }}
                                >
                                    {t('verosimilitud.buttons.saveDraft')}
                                </Button>

                                <Button onClick={handleEnviarPresentacion} variant="contained">
                                    {t('verosimilitud.buttons.send')}
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>

            <BackdropComponent loading={loading} />
        </div>
    );
};

export default RGRLVerosimilitud;
