import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

interface ContractState {
    selectedContractNumber: number | null;
}

const initialState: ContractState = {
    selectedContractNumber: null
};

const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setSelectedContractNumber: (state, action: PayloadAction<number | null>) => {
            state.selectedContractNumber = action.payload;
        },
        clearSelectedContractNumber: (state) => {
            state.selectedContractNumber = null;
        }
    }
});

export const { setSelectedContractNumber, clearSelectedContractNumber } = contractSlice.actions;

const persistConfig = {
    key: 'contract',
    storage
};

export default persistReducer(persistConfig, contractSlice.reducer);
