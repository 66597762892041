import React, { useEffect, useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { hideAlert, showAlert } from '../../../features/slices/applicationSlice';
import { usePatchVisitaEstadoMutation } from '../../../services/visitasApi';
import { Visita } from '../consts/visitasTypes';
import { getDateTime } from '../../../utils/common';
import { DrawerContainer } from '../../../components/Drawer/DrawerContainer';
import { DrawerHeader } from '../../../components/Drawer/DrawerHeader';
import { enqueueSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { useGetEstablishmentDetailQuery } from '../../../services/companyApi';
import EstablishmentDetails from './EstablishmentDetails';
import VisitDetails from './VisitaDetails';

interface DrawerVisitasProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    actionType: 'Detalle' | 'Cancelar' | 'Rechazar' | 'Establecimiento' | null;
    visita: Visita | null;
}

const DrawerVisitas: React.FC<DrawerVisitasProps> = ({ open, setOpen, actionType, visita }) => {
    const { t } = useTranslation('visitas');
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [observacion, setObservacion] = useState('');
    const [patchVisitaEstado, { isLoading }] = usePatchVisitaEstadoMutation();
    const user = useAppSelector((state) => state.auth.user);
    const idEstablecimiento = location.state?.selectedVisita?.idEstablecimiento;
    const { data: establishmentDetails, isLoading: loadingEstablishment } =
        useGetEstablishmentDetailQuery(
            { establishmentId: idEstablecimiento },
            { skip: !idEstablecimiento }
        );

    const handleConfirm = async () => {
        if (!visita) return;

        if (!observacion.trim()) {
            enqueueSnackbar(t('drawer.warningReason', { action: actionType?.toLowerCase() }), {
                variant: 'warning'
            });
            return;
        }

        const estado = actionType === 'Cancelar' ? 4 : 5;
        const fechaHora = getDateTime(new Date().toISOString());
        const observacionPrevia = visita.observacion ? `${visita.observacion}\n` : '';
        const nombreUsuario = user?.nombre || '';
        const cuilUsuario = user?.cuil || '';

        const observacionFinal = `${observacionPrevia}${fechaHora} - ${
            actionType === 'Cancelar' ? 'Cancelado' : 'Rechazado'
        } por ${nombreUsuario} (CUIL: ${cuilUsuario}) - Motivo: ${observacion}`;

        try {
            await patchVisitaEstado({
                idVisita: visita.id,
                estado,
                observacion: observacionFinal
            }).unwrap();

            enqueueSnackbar(
                t(actionType === 'Cancelar' ? 'drawer.successCancel' : 'drawer.successReject'),
                { variant: 'success' }
            );
            setOpen(false);
        } catch (error) {
            enqueueSnackbar(t('drawer.errorUpdate'), { variant: 'error' });
        }
    };

    const confirmAction = () => {
        dispatch(
            showAlert({
                title: t(
                    actionType === 'Cancelar' ? 'drawer.confirmCancel' : 'drawer.confirmReject'
                ),
                icon: 'info',
                keepMounted: true,
                confirmText: t('drawer.confirm'),
                cancelText: t('drawer.cancel'),
                cancelAction: () => dispatch(hideAlert()),
                confirmAction: handleConfirm
            })
        );
    };

    useEffect(() => {
        if (!open) {
            setObservacion('');
        }
    }, [open]);

    return (
        <DrawerContainer open={open} setOpen={setOpen}>
            <DrawerHeader
                onIconClick={() => setOpen(false)}
                text={t(`drawer.title.${actionType?.toLowerCase()}`)}
            />

            {(actionType === 'Detalle' || actionType === 'Cancelar' || actionType === 'Rechazar') &&
                visita && <VisitDetails visita={visita} />}

            {actionType !== 'Detalle' && actionType !== 'Establecimiento' && (
                <>
                    <TextField
                        label={
                            actionType === 'Cancelar'
                                ? t('drawer.cancelReason')
                                : t('drawer.rejectReason')
                        }
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={observacion}
                        inputProps={{ maxLength: 500 }}
                        onChange={(e) => setObservacion(e.target.value)}
                        helperText={`${observacion.length}/500`}
                        error={observacion.length > 0 && observacion.length < 10}
                    />

                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 16,
                            right: 16,
                            display: 'flex',
                            gap: 2
                        }}
                    >
                        <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
                            {t('drawer.close')}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={confirmAction}
                            disabled={isLoading || observacion.trim().length < 10}
                        >
                            {t(
                                actionType === 'Cancelar'
                                    ? 'drawer.cancelVisit'
                                    : 'drawer.rejectVisit'
                            )}
                        </Button>
                    </Box>
                </>
            )}

            {actionType === 'Establecimiento' && !loadingEstablishment && establishmentDetails && (
                <EstablishmentDetails
                    establishment={establishmentDetails}
                    loading={loadingEstablishment}
                />
            )}
        </DrawerContainer>
    );
};

export default DrawerVisitas;
