import { useGetPresentacionQuery } from '../../../../services/presentacionesApi';
import { useAppDispatch } from '../../../../hooks/reduxHooks';

const useRGRLVerosimilitud = (idPresentacion: number | undefined) => {
    const dispatch = useAppDispatch();
    const {
        data: responsePresentacion,
        error,
        isLoading
    } = useGetPresentacionQuery(idPresentacion);

    return {
        responsePresentacion,
        error,
        isLoading
    };
};

export default useRGRLVerosimilitud;
