import { Box, Drawer } from '@mui/material';
import { useIsMobile } from '../../hooks/useIsMobile';

const DrawerContainer = ({ children, setOpen, open, customWidth }: any) => {
    const { isMobile } = useIsMobile();

    return (
        <Drawer
            anchor="right"
            open={!!open}
            onClose={() => {
                setOpen('');
            }}
            PaperProps={{
                sx: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            <Box
                sx={{
                    marginTop: '34px',
                    width: isMobile ? '100vw' : customWidth || 481,
                    padding: '20px',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    '& .MuiTextField-root': { m: '8px 0', width: '100%' }
                }}
                role="presentation"
            >
                <Box component="div" sx={{ flex: 1, overflowY: 'auto' }}>
                    {children}
                </Box>
            </Box>
        </Drawer>
    );
};

export { DrawerContainer };