import { Box } from '@mui/material';
import { useIsMobile } from '../../hooks/useIsMobile';
import AddIcon from '@mui/icons-material/Add';
import Button from '../button/Button';

export const IconHeader = ({
    text,
    onClick,
    showIcon = true,
    variant = 'contained',
    loading = false,
    disabled = false,
    testID = ''
}: any) => {
    const { isMobile } = useIsMobile();
    if (!text) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'right',
                '& span': {
                    margin: isMobile ? 0 : null
                }
            }}
        >
            <Button
                startIcon={showIcon ? <AddIcon /> : <></>}
                onClick={onClick || null}
                type={variant}
                loading={loading}
                disabled={disabled}
                testID={testID}
                style={{
                    marginRight: '8px',
                    borderRadius: '8px',
                    textTransform: 'none',
                    width: 'auto',
                    minWidth: isMobile ? 'auto' : '170px',
                    height: '48px'
                }}
            >
                {text}
            </Button>
        </Box>
    );
};
