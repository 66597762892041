import { Chip, Tooltip } from '@mui/material';
import { visitaEstadoChipMap, VisitaEstado } from '../consts/visitasStatus';

interface VisitaEstadoChipProps {
    estadoId: number;
    observacion?: string;
}

const VisitaEstadoChip = ({ estadoId, observacion }: VisitaEstadoChipProps) => {
    const chip = visitaEstadoChipMap[estadoId as VisitaEstado] || {
        textColor: 'white',
        backgroundColor: 'gray',
        text: 'Desconocido'
    };

    const chipElement = (
        <Chip
            label={chip.text}
            sx={{
                backgroundColor: chip.backgroundColor,
                color: chip.textColor,
                fontWeight: 600,
                fontSize: '0.75rem',
                maxWidth: 160
            }}
        />
    );

    if (estadoId === VisitaEstado.RECHAZADO && observacion) {
        return (
            <Tooltip
                title={observacion?.match(/\d{2}\/\d{2}\/\d{4}.*$/)?.[0] || observacion}
                arrow
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#384A52 !important',
                            fontSize: '0.875rem',
                            whiteSpace: 'pre-line',
                            maxWidth: 300
                        }
                    }
                }}
            >
                {chipElement}
            </Tooltip>
        );
    }

    return chipElement;
};

export { VisitaEstadoChip };
