export type PRESENTATION_STATE_UPDATE_TYPE = 'REVISE' | 'VISIT' | 'CONFORM' | 'CANCEL';
export const PRESENTATION_STATE_UPDATE_TYPES = {
    REVISE: 'REVISE',
    VISIT: 'VISIT',
    CONFORM: 'CONFORM',
    CANCEL: 'CANCEL'
} as const;

export enum PRESENTATION_STATE {
    BORRADOR = 1,
    RECIBIDO = 2, //antes era presentado
    EN_REVISION = 3,
    PENDIENTE_VISITA = 4,
    PRESENTADO = 5, //antes era conformado
    CANCELADO = 6,
    PROCESANDO = 7,
    ERROR_PROCESAMIENTO = 8,
    PENDIENTE_RGRL
}

export enum PRESENTATION_TYPE {
    RGRL_351 = 1,
    RGRL_617 = 2,
    RGRL_911 = 3,
    RAR = 4
}

export enum RESPONSEOPTIONS {
    SI = 'SI',
    NO = 'NO',
    NA = 'N/A'
}

export type ALERT_TYPE = 'CONFIRM_REVISE' | 'CONFIRM_VISIT' | 'CONFIRM_CONFORM' | 'CONFIRM_CANCEL';

const ALERT_TYPES = {
    CONFIRM_REVISE: 'CONFIRM_REVISE',
    CONFIRM_VISIT: 'CONFIRM_VISIT',
    CONFIRM_CONFORM: 'CONFIRM_CONFORM',
    CONFIRM_CANCEL: 'CONFIRM_CANCEL'
};

const ALERT_TITLES = {
    [ALERT_TYPES.CONFIRM_REVISE]:
        'Si cambias una presentación en estado “Recibido” ya no podrás volver a este estado. Solo podrás utilizar los estados disponibles en las opciones.',
    [ALERT_TYPES.CONFIRM_VISIT]:
        'Si cambias una presentación en estado “Recibido” ya no podrás volver a este estado. Solo podrás utilizar los estados disponibles en las opciones.',
    [ALERT_TYPES.CONFIRM_CONFORM]:
        'Si cambias una presentación en estado “Recibido” ya no podrás volver a este estado. Solo podrás utilizar los estados disponibles en las opciones.',
    [ALERT_TYPES.CONFIRM_CANCEL]: 'Estás seguro de que deseas rechazar la presentación?'
};

const ALERT_CONFIRM_ACTIONS = {
    [ALERT_TYPES.CONFIRM_REVISE]: PRESENTATION_STATE_UPDATE_TYPES.REVISE,
    [ALERT_TYPES.CONFIRM_VISIT]: PRESENTATION_STATE_UPDATE_TYPES.VISIT,
    [ALERT_TYPES.CONFIRM_CONFORM]: PRESENTATION_STATE_UPDATE_TYPES.CONFORM,
    [ALERT_TYPES.CONFIRM_CANCEL]: PRESENTATION_STATE_UPDATE_TYPES.CANCEL
};

export { ALERT_TYPES, ALERT_TITLES, ALERT_CONFIRM_ACTIONS };
