import React, { useState, useEffect } from 'react';
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Radio,
    Stack,
    IconButton,
    RadioGroup,
    FormControlLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDate } from '../../../utils/common';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface VerosimilitudProps {
    secciones: {
        id: number;
        titulo: string;
        preguntas: {
            id: number;
            orden: number;
            texto: string;
            tiposRespuesta: {
                id: number;
                descripcion: string;
                requiereFecha: boolean;
                tipoComponente: string;
                valorDefecto: boolean;
                orden: number;
                selected?: boolean;
                fecha?: string | null;
            }[];
            norma: string;
        }[];
    }[];
    respuestas: any[];
    setRespuestas: React.Dispatch<React.SetStateAction<any[]>>;
}

interface VerosimilitudSeleccion {
    idSeccion: number;
    opciones: {
        id: number;
        selected: boolean;
    }[];
}

const VerosimilitudGrid: React.FC<VerosimilitudProps> = ({ secciones }) => {
    const { t } = useTranslation('visitas');

    const [verosimilitudSeleccion, setVerosimilitudSeleccion] = useState<VerosimilitudSeleccion[]>(
        []
    );
    const [expanded, setExpanded] = useState<number | false>(false);

    const handleAccordionChange = (id: number) => (_: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? id : false);
    };

    useEffect(() => {
        if (secciones.length === 0) return;

        const initial: VerosimilitudSeleccion[] = secciones.map((s) => ({
            idSeccion: s.id,
            opciones: [
                { id: 1, selected: false },
                { id: 2, selected: false },
                { id: 3, selected: true }
            ]
        }));

        setVerosimilitudSeleccion(initial);
        localStorage.setItem('verosimilitud', JSON.stringify(initial));
    }, [secciones]);

    useEffect(() => {
        localStorage.setItem('verosimilitud', JSON.stringify(verosimilitudSeleccion));
    }, [verosimilitudSeleccion]);

    const handleRadioChange = (idSeccion: number, opcionId: number) => {
        const updated = verosimilitudSeleccion.map((registro) => {
            if (registro.idSeccion === idSeccion) {
                return {
                    ...registro,
                    opciones: registro.opciones.map((op) => ({
                        ...op,
                        selected: op.id === opcionId
                    }))
                };
            }
            return registro;
        });

        setVerosimilitudSeleccion(updated);
    };

    const getRadioValue = (idSeccion: number): string => {
        const verosimilitud = verosimilitudSeleccion.find((v) => v.idSeccion === idSeccion);
        const selected = verosimilitud?.opciones.find((op) => op.selected);
        return selected?.id.toString() || '3';
    };

    const getRespuestaSeleccionada = (pregunta: any) => {
        return pregunta.tiposRespuesta.find((r: any) => r.selected);
    };

    const getFechaRegulacion = (respuestaSeleccionada: any): string => {
        if (respuestaSeleccionada?.requiereFecha) {
            return respuestaSeleccionada.fecha ? getDate(respuestaSeleccionada.fecha) : '-';
        }
        return '-';
    };

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={2}
                py={4}
                mb={1}
                sx={{
                    backgroundColor: '#384A52',
                    color: 'white'
                }}
            >
                <Typography color={'white'} variant="body2" fontWeight="bold">
                    {t('verosimilitud.sections')}
                </Typography>

                <Stack
                    direction="row"
                    spacing={4}
                    sx={{ minWidth: 120, justifyContent: 'flex-end', paddingRight: '64px' }}
                >
                    <Typography color={'white'} variant="body2" align="center" sx={{ width: 24 }}>
                        {t('verosimilitud.radioLabels.cumple')}
                    </Typography>
                    <Typography color={'white'} variant="body2" align="center" sx={{ width: 24 }}>
                        {t('verosimilitud.radioLabels.incumple')}
                    </Typography>
                    <Typography color={'white'} variant="body2" align="center" sx={{ width: 24 }}>
                        {t('verosimilitud.radioLabels.noAplica')}
                    </Typography>
                </Stack>
            </Box>

            {secciones.map((seccion) => (
                <Accordion
                    key={seccion.id}
                    expanded={expanded === seccion.id}
                    onChange={handleAccordionChange(seccion.id)}
                >
                    <AccordionSummary
                        expandIcon={
                            <IconButton>
                                <ExpandMoreIcon sx={{ color: '#384A52' }} />
                            </IconButton>
                        }
                        sx={{
                            backgroundColor: expanded === seccion.id ? '#E0EBED' : 'white',
                            color: '#384A52'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{ fontWeight: '600' }}>{seccion.titulo}</Typography>

                            <RadioGroup
                                row
                                value={getRadioValue(seccion.id)}
                                onChange={(_, newValue) => {
                                    const opcionId = parseInt(newValue, 10);
                                    handleRadioChange(seccion.id, opcionId);
                                }}
                                sx={{ ml: 2 }}
                            >
                                {[1, 2, 3].map((opId) => (
                                    <FormControlLabel
                                        key={opId}
                                        value={opId.toString()}
                                        sx={{ mr: 3 }}
                                        control={
                                            <Radio
                                                onClick={(e) => e.stopPropagation()}
                                                onFocus={(e) => e.stopPropagation()}
                                                sx={{
                                                    color: '#384A52',
                                                    '&.Mui-checked': {
                                                        color: '#384A52'
                                                    }
                                                }}
                                                checkedIcon={
                                                    <span
                                                        style={{
                                                            borderRadius: '50%',
                                                            backgroundColor: '#384A52',
                                                            width: 12,
                                                            height: 12,
                                                            display: 'block',
                                                            border: '2px solid #384A52'
                                                        }}
                                                    />
                                                }
                                                icon={
                                                    <span
                                                        style={{
                                                            borderRadius: '50%',
                                                            border: '2px solid #384A52',
                                                            width: 12,
                                                            height: 12,
                                                            display: 'block'
                                                        }}
                                                    />
                                                }
                                            />
                                        }
                                        label=""
                                    />
                                ))}
                            </RadioGroup>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#F6F6F6' }}>
                                        <TableCell
                                            sx={{
                                                width: '5%',
                                                backgroundColor: '#F6F6F6',
                                                color: '#1E272E',
                                                fontWeight: '600'
                                            }}
                                        >
                                            <TableCell>{t('verosimilitud.columns.nro')}</TableCell>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '65%',
                                                backgroundColor: '#F6F6F6',
                                                color: '#1E272E',
                                                fontWeight: '600'
                                            }}
                                        >
                                            <TableCell>
                                                {t('verosimilitud.columns.condition')}
                                            </TableCell>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '10%',
                                                backgroundColor: '#F6F6F6',
                                                color: '#1E272E',
                                                fontWeight: '600'
                                            }}
                                        >
                                            <TableCell>
                                                {t('verosimilitud.columns.response')}
                                            </TableCell>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                width: '10%',
                                                backgroundColor: '#F6F6F6',
                                                color: '#1E272E',
                                                fontWeight: '600'
                                            }}
                                        >
                                            <TableCell>
                                                {t('verosimilitud.columns.regulationDate')}
                                            </TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {seccion.preguntas.map((pregunta) => {
                                        const respuestaSeleccionada =
                                            getRespuestaSeleccionada(pregunta);
                                        return (
                                            <TableRow key={pregunta.id}>
                                                <TableCell>{pregunta.orden}</TableCell>
                                                <TableCell>{pregunta.texto}</TableCell>
                                                <TableCell>
                                                    {respuestaSeleccionada?.descripcion || '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {getFechaRegulacion(respuestaSeleccionada)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default VerosimilitudGrid;
