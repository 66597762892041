import React, { ReactNode } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styles from './styles.module.css';
import { DefaultTFuncReturn } from 'i18next';

interface SectionFormBoxProps {
    children: ReactNode;
    className?: string;
    title: string | DefaultTFuncReturn;
    testID?: string;
    subtitle?: string;
}

const SectionFormBox: React.FC<SectionFormBoxProps> & {
    Right: React.FC<{ children: ReactNode }>;
} = ({ children, className, title, testID, subtitle = '' }) => {
    let rightComponent: ReactNode = null;

    const childrenArray = React.Children.toArray(children);
    const mainChildren = childrenArray.filter((child) => {
        if (
            React.isValidElement(child) &&
            (child.type as any).displayName === 'SectionFormBoxRight'
        ) {
            rightComponent = child;
            return false;
        }
        return true;
    });

    return (
        <Box
            className={className && styles[className]}
            data-testid={testID}
            sx={{
                borderRadius: 2,
                bgcolor: '#fff',
                padding: 2,
                marginBottom: 3
            }}
        >

            <Grid container spacing={0} rowGap={1} mb={1} columnSpacing={{ xs: 12, sm: 6, md: 2, lg: 2 }}>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mb: 3.5 }}
                    className={styles['box-title']}
                >
                    {title}
                </Typography>
                {rightComponent && <Box>{rightComponent}</Box>}
  
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ mb: !subtitle ? 0 : 4, color: '#384A52', fontSize: '16px', fontWeight: 400 }}>
                    {subtitle}
                </Typography>
            </Grid>
                {mainChildren}
            </Grid>
        </Box>
    );
};

const SectionFormBoxRight: React.FC<{ children: ReactNode }> = ({ children }) => {
    return <>{children}</>;
};

SectionFormBoxRight.displayName = 'SectionFormBoxRight';
SectionFormBox.Right = SectionFormBoxRight;

export default SectionFormBox;
