import _ from 'lodash';
import { useEffect, useState } from 'react';

function useGlobalFilters(props?: any) {
    const defaultFilters = props || { skip: 0, take: 10 };
    const [filters, setFilters] = useState<any>(defaultFilters || null);
    const [dateFilters, setDateFilters] = useState<{
        fechaInicio: string | null;
        fechaFin: string | null;
    }>({ fechaInicio: null, fechaFin: null });
    const [extraFilters, setExtraFilters] = useState<any>(null);
    const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);

    const clearFilters = () => {
        setExtraFilters(null);
        setFilters(defaultFilters);
        setDateFilters({ fechaInicio: null, fechaFin: null });
    };
    const handleChangeFilter = (e: any) => {
        if (!e?.target) return;
        const { name, value }: any = e?.target;
        if (name && !value) {
            const newFilters = { ...filters };
            delete newFilters[name];
            setFilters(newFilters);
        } else
            setFilters({
                ...filters,
                ...defaultFilters,
                [name]: value
            });
    };

    const handleChangeExtraFilters = (e: any) => {
        const { name, value, type, checked }: any = e?.target;
        if (!value) {
            let newObject = { ...extraFilters };
            delete newObject[name];
            let newFilterObject = { ...filters };
            if (name === 'userState') {
                if (newFilterObject.hasOwnProperty('activo')) delete newFilterObject.activo;
                if (newFilterObject.hasOwnProperty('bloqueado')) delete newFilterObject.bloqueado;
            } else {
                delete newFilterObject[name];
            }
            setFilters(newFilterObject);
            setExtraFilters(newObject);
        } else {
            if (type && type === 'checkbox') {
                setExtraFilters({
                    ...extraFilters,
                    [name]: checked
                });
            } else if (typeof value === 'object' && type && type !== 'autocomplete') {
                setExtraFilters({
                    ...extraFilters,
                    [name]: value?.value || value?.nombre
                });
            } else {
                setExtraFilters({
                    ...extraFilters,
                    [name]: value
                });
            }
        }
        setShouldSubmit(true);
    };

    const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
        setDateFilters({
            fechaInicio: startDate ? startDate.toISOString() : null,
            fechaFin: endDate ? endDate.toISOString() : null
        });
    };

    const handleSubmitSearch = () => {
        let newFilters: { [key: string]: any } = {};

        let controlledFilters = { ...filters };
        _.entries(extraFilters).forEach((key: any, value: any) => {
            if (typeof key[1] === 'object') {
                let value = key[1]?.nombre;
                if (['activo', 'ingreso'].includes(key[0])) value = key[1].value;
                if (key[0].includes('id')) value = key[1].id;
                if (key[0].includes('perfiles')) value = key[1].id;
                if (key[0].includes('risks')) value = key[1].value;
                if (key[0].includes('expuesto')) value = key[1].value;
                if (key[0].includes('expositionDate')) value = key[1].value;
                if (key[0].includes('fecha')) value = key[1];
                if (key[0].includes('estado')) value = key[1].value;
                if (key[0].includes('presentacionRGRL') || key[0].includes('presentacionRAR'))
                    value = key[1].value;
                if (key[0].includes('idCiiu')) value = key[1].id;
                if (key[0].includes('organismo')) value = key[1].id;
                if (key[0].includes('nroContrato')) value = key[1].id;
                if (key[0].includes('idOrganismoCentral')) value = key[1].id;
                if (key[0].includes('codigoRiesgo')) value = key[1].codigo;
                if (key[0].includes('idRol')) value = key[1].id;
                if (key[0].includes('userState')) {
                    let name = '';
                    if (['Activo', 'Inactivo'].includes(key[1].nombre)) {
                        name = 'activo';
                        if (controlledFilters.hasOwnProperty('bloqueado'))
                            delete controlledFilters?.bloqueado;
                    } else {
                        name = 'bloqueado';
                        if (controlledFilters.hasOwnProperty('activo'))
                            delete controlledFilters?.activo;
                    }
                    newFilters[name] = key[1].value;
                } else {
                    newFilters[key[0]] = value;
                }
            } else {
                newFilters[key[0]] = key[1];
            }
        });

        setFilters({
            ...controlledFilters,
            ...defaultFilters,
            ...newFilters,
            ...dateFilters
        });
        setShouldSubmit(false);
    };

    const debounceSearch = _.debounce(handleChangeFilter, 700);

    useEffect(() => {
        if (shouldSubmit) {
            handleSubmitSearch();
            setShouldSubmit(false);
        }
        // eslint-disable-next-line
    }, [filters, dateFilters]);

    useEffect(() => {
        return () => debounceSearch.cancel();
        // eslint-disable-next-line
    }, []);

    //This function is for cases where you need to filter data without filling input fields, for exapmle, when clicking a Card.
    const handleDirectFilter = (newFilters: any) => {
        setFilters({ ...defaultFilters, ...newFilters });
        setShouldSubmit(true);
    };

    return {
        filters,
        extraFilters,
        dateFilters,
        debounceSearch,
        setFilters,
        clearFilters,
        setExtraFilters,
        handleSubmitSearch,
        handleChangeExtraFilters,
        handleDateChange,
        handleDirectFilter
    };
}

export default useGlobalFilters;
