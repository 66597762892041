import React from 'react';
import { Collapse, Grid, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import useToggle from '../../../components/Cabeceras/hooks/useToggle';

interface ResponsableCargaSectionProps {
    responsables: any[];
}

const ResponsableCargaSection: React.FC<ResponsableCargaSectionProps> = ({ responsables }) => {
    const { t } = useTranslation('presentacionesRar');
    const [isExpanded, toggleExpanded] = useToggle(true);

    const responsablesR = responsables?.filter((r: any) => r?.cargo?.codigo === 'R');

    if (!responsablesR || responsablesR.length === 0) return null;

    return (
        <div style={{ background: 'white', paddingLeft: '80px', marginTop: '-20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Typography variant="h3" sx={{ fontWeight: '600', color: '#1E272E' }}>
                    {t('responsableData.title')}
                </Typography>
                <IconButton onClick={toggleExpanded} sx={{ ml: 1 }}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </div>
            <Collapse in={isExpanded}>
                {responsablesR.map((responsable: any, index: number) => (
                    <Grid container spacing={2} sx={{ pb: 4 }} key={index}>
                        <Grid item xs={2}>
                            <Typography variant="body1">
                                <strong>{t('responsableData.name')}:</strong>
                                <br />
                                {responsable.nombre || ''} {responsable.apellido || ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1">
                                <strong>{t('responsableData.CUIT')}:</strong>
                                <br />
                                {responsable.cuit || 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1">
                                <strong>{t('responsableData.cargo')}:</strong>
                                <br />
                                {responsable.cargo?.nombre || 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1">
                                <strong>{t('responsableData.status')}:</strong>
                                <br />
                                {responsable.tipoContratacion?.nombre ||
                                    responsable.tipoContratacion ||
                                    'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body1">
                                <strong>{t('responsableData.tituloHabilitante')}:</strong>
                                <br />
                                {responsable.titulo || 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body1">
                                <strong>{t('responsableData.matriculaNumber')}:</strong>
                                <br />
                                {responsable.matricula || 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1">
                                <strong>{t('responsableData.entidadOtorgante')}:</strong>
                                <br />
                                {responsable.entidadOtorgante || 'N/A'}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </Collapse>
        </div>
    );
};

export default ResponsableCargaSection;
