import React from 'react';
import 'dayjs/locale/es';
import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';


interface DateRangePickerProps {
  startDate: any;
  endDate: any;
  onChange: (startDate: any, endDate: any) => void;
}

const DateRangePickerComponent: React.FC<DateRangePickerProps> = ({ startDate, endDate, onChange }) => {
  const today = dayjs(); 

  const handleStartDateChange = (date: any) => {
    onChange(date, endDate);
  };

  const handleEndDateChange = (date: any) => {
    onChange(startDate, date);
  };

  const clearStartDate = () => {
    onChange(null, endDate);
  };

  const clearEndDate = () => {
    onChange(startDate, null);
  };

  return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ position: 'relative', marginRight: 16 }}>
              <DatePicker
                  slotProps={{
                      textField: {
                          InputProps: {
                              inputProps: { 'data-testid': 'range-startDate' }
                          }
                      }
                  }}
                  label="Fecha de Inicio"
                  value={startDate}
                  onChange={handleStartDateChange}
                  disableFuture={true}
                  maxDate={endDate || today}
                  format="DD/MM/YYYY"
              />
              {startDate && (
                  <IconButton
                      onClick={clearStartDate}
                      style={{ position: 'absolute', top: 16, right: 40 }}
                      size="small"
                  >
                      <ClearIcon />
                  </IconButton>
              )}
          </div>
          <div style={{ position: 'relative' }}>
              <DatePicker
                  slotProps={{
                      textField: {
                          InputProps: {
                              inputProps: { 'data-testid': 'range-endDate' }
                          }
                      }
                  }}
                  label="Fecha de Fin"
                  value={endDate}
                  onChange={handleEndDateChange}
                  disableFuture={true}
                  minDate={startDate || null}
                  maxDate={today}
                  format="DD/MM/YYYY"
              />
              {endDate && (
                  <IconButton
                      onClick={clearEndDate}
                      style={{ position: 'absolute', top: 16, right: 40 }}
                      size="small"
                  >
                      <ClearIcon />
                  </IconButton>
              )}
          </div>
      </div>
  );
};

export default DateRangePickerComponent;