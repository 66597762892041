import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/functions';

const Breadcrumb = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const pathSegments = location.pathname.split('/').filter((x) => x);

    const filteredSegments = pathSegments
        .slice(0, -1)
        .filter((segment) => !['editar-rar', 'visualizar-rar'].includes(segment.toLowerCase()));

        const manipulatedSegments = filteredSegments.map((segment, index) => {
            if (index === 1 && segment === 'establecimiento') {
                return 'establecimientos';
            }
            return segment;
        });

    const breadcrumbUrls = manipulatedSegments.map((_, index) => {
        let constructedUrl = `/${manipulatedSegments.slice(0, index + 1).join('/')}`;

        if (constructedUrl.includes('/establecimientos/presentaciones')) {
            constructedUrl = constructedUrl.replace(
                '/establecimientos/presentaciones',
                '/establecimiento/presentaciones'
            );
        }
        return constructedUrl;
    });

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, to: string) => {
        if (
            to === '/simp/establecimiento/presentaciones' ||
            to === '/backoffice/establecimiento/presentaciones'
        ) {
            event.preventDefault();
            navigate(-1);
        }
    };

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '14px', color: 'primary.main' }}>
            {breadcrumbUrls.length > 0 ? (
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    Home
                </Link>
            ) : (
                <Typography color="textPrimary">Home</Typography>
            )}

            {breadcrumbUrls.map((url, index) => {
                const segment = manipulatedSegments[index];

                if (segment.toLowerCase() === 'simp' || segment.toLowerCase() === 'backoffice') {
                    return null;
                }

                return (
                    <Link
                        key={url}
                        to={url}
                        onClick={(event) => handleClick(event, url)}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        {capitalizeFirstLetter(segment.replace(/-/g, ' '))} 
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default Breadcrumb;
