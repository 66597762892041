import {
    Box,
    Grid,
    Typography,
    TextField,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Select,
    IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import SectionHeader from '../../../components/SectionHeader';
import { CircularProgress } from '@mui/material';
import DrawerVisitas from '../components/DrawerVisitas';
import { useEjecutionsVisits } from './hooks/useEjecutionVisits';
import { PresentationStateChip } from '../../Presentaciones/components/PresentationStateChip';
import { PRESENTATION_STATE } from '../../Presentaciones/consts';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogNewRGRL } from '../../Presentaciones/components/DialogNewRGRL';
import { useNavigate } from 'react-router-dom';
import { VisitaEstadoChip } from '../components/EstadoChip';
import { VisitaEstado } from '../consts/visitasStatus';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

function EjecutionsVisitsIndex() {
    const { t } = useTranslation('visitas');
    const navigate = useNavigate();
    const {
        formData,
        setFormData,
        isFormValid,
        handleChange,
        handleFalloVisitaChange,
        handleSubmit,
        handleClearSignature,
        openDrawer,
        setOpenDrawer,
        handleOpenDrawer,
        selectedVisita,
        selectedVisitaDetail,
        canvasPreventorRef,
        canvasResponsableRef,
        tiposVisitasOptions,
        subTypesVisitasOptions,
        loadingTiposVisitas,
        signatureErrors,
        confirmSubmit,
        rgrlPresentaciones,
        openDialogRGRL,
        handleOpenDialogRGRL,
        handleCloseDialogRGRL,
        handleCardClickRGRL,
        handleOpenVerosimilitud,
        loadingPresentaciones,
        shouldShowVerosimilitudRow,
        isVerosimilitudDraft,
        isVerosimilitudFinalizada,
        confirmCancelPresentation,
        allPresentationsCancelled,
        handleEditRGRL
    } = useEjecutionsVisits();

    return (
        <>
            <SectionHeader>
                <SectionHeader.Title>
                    {t('newVisit.detail')} : {selectedVisita.nombreEstablecimiento || '-'}
                </SectionHeader.Title>

                <SectionHeader.IconHeader
                    showIcon={false}
                    variant="outlined"
                    text={t('newVisit.establishment')}
                    onClick={handleOpenDrawer}
                    fullwidth
                />
                <Grid container sx={{ p: 2 }}>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="h6">
                            <strong>{t('filters.visitType')}</strong>
                        </Typography>
                        <Typography variant="body2">
                            {selectedVisita.nombreTipoVisita || '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Typography variant="h6">
                            <strong>{t('filters.deadlineDate')}</strong>
                        </Typography>
                        <Typography variant="body2">
                            {selectedVisita.fechaLimite ? dayjs().format('DD/MM/YYYY') : '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Typography variant="h6">
                            <strong>{t('filters.visitDate')}</strong>
                        </Typography>
                        <Typography variant="body2">
                            {selectedVisita.fechaVisita ? dayjs().format('DD/MM/YYYY') : '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Typography variant="h6">
                            <strong>{t('filters.preventor')}</strong>
                        </Typography>
                        <Typography variant="body2">
                            {selectedVisita.nombrePreventor || '-'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Typography variant="h6">
                            <strong>{t('filters.observations')}</strong>
                        </Typography>
                        <Typography variant="body2">{selectedVisita.observacion || '-'}</Typography>
                    </Grid>
                </Grid>
            </SectionHeader>

            <Box
                component="div"
                justifyContent="center"
                sx={{ mt: 4, backgroundColor: 'white', p: 2 }}
            >
                <Typography variant="h3">
                    <strong>{t('newVisit.sectionSummary')}</strong>
                </Typography>

                <Grid container spacing={1} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.esFallida}
                                    onChange={handleFalloVisitaChange}
                                />
                            }
                            label={t('newVisit.label.failedVisit')}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label={t('newVisit.label.totalStaff')}
                            name="totalPersonal"
                            type="number"
                            value={formData.totalPersonal ?? ''}
                            onChange={handleChange}
                            disabled={formData.esFallida}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label={t('newVisit.label.administrativeStaff')}
                            name="administrativos"
                            type="number"
                            value={formData.administrativos ?? ''}
                            onChange={handleChange}
                            disabled={formData.esFallida}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label={t('newVisit.label.productionStaff')}
                            name="personalProduccion"
                            type="number"
                            value={formData.personalProduccion ?? ''}
                            onChange={handleChange}
                            disabled={formData.esFallida}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel disabled={formData.esFallida}>
                                {t('newVisit.label.safetyService')}
                            </InputLabel>
                            <Select
                                name="seguridadHigiene"
                                value={formData.seguridadHigiene ?? ''}
                                onChange={(event) => {
                                    setFormData((prev: any) => ({
                                        ...prev,
                                        seguridadHigiene: event.target.value === 'true'
                                    }));
                                }}
                                disabled={formData.esFallida}
                            >
                                <MenuItem value={'true'}>{t('newVisit.common.yes')}</MenuItem>
                                <MenuItem value={'false'}>{t('newVisit.common.no')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label={t('newVisit.label.professional')}
                            name="profesional"
                            value={formData.profesional}
                            onChange={handleChange}
                            disabled={formData.esFallida || formData.seguridadHigiene === false}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label={t('newVisit.label.license')}
                            name="matricula"
                            value={formData.matricula}
                            onChange={handleChange}
                            disabled={formData.esFallida || formData.seguridadHigiene === false}
                        />
                    </Grid>
                    {selectedVisita?.nombreTipoVisita === 'GENERICA' && (
                        <Grid item xs={12} sm={12}>
                            <TextField
                                select
                                fullWidth
                                label={t('newVisit.label.subVisitType')}
                                name="IdSubTipoVisita"
                                value={formData.IdSubTipoVisita ?? ''}
                                onChange={handleChange}
                                disabled={loadingTiposVisitas || formData.esFallida}
                            >
                                {loadingTiposVisitas ? (
                                    <MenuItem disabled></MenuItem>
                                ) : (
                                    subTypesVisitasOptions.map((option: any) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre}
                                        </MenuItem>
                                    ))
                                )}
                            </TextField>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            label={t('newVisit.label.observation')}
                            name="observacion"
                            value={formData.observacion}
                            onChange={handleChange}
                            disabled={false}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={0} sx={{ p: 2 }} justifyContent={'space-between'}>
                    <Grid
                        item
                        xs={12}
                        sm={5.8}
                        sx={{ p: 2, borderRadius: '5px', border: '1px solid #0000003b' }}
                    >
                        <canvas
                            ref={canvasPreventorRef}
                            width={300}
                            height={150}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderBottom: '1px solid #000',
                                background: '#F6F6F6'
                            }}
                        />
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={1}
                        >
                            <Typography variant="body2">
                                {t('newVisit.label.preventorSignature')}
                            </Typography>
                            <Button
                                onClick={() => handleClearSignature('preventor')}
                                variant="outlined"
                            >
                                {t('newVisit.common.clear')}
                            </Button>
                        </Box>
                        {signatureErrors.firmaPreventor && (
                            <Typography variant="body2" color="error">
                                {t('newVisit.enqueue.signatureError')}
                            </Typography>
                        )}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={5.8}
                        sx={{ p: 2, borderRadius: '5px', border: '1px solid #0000003b' }}
                    >
                        <canvas
                            ref={canvasResponsableRef}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderBottom: '1px solid #000',
                                background: '#F6F6F6'
                            }}
                        />
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={1}
                        >
                            <Typography variant="body2">
                                {t('newVisit.label.responsibleSignature')}
                            </Typography>
                            <Button
                                onClick={() => handleClearSignature('responsable')}
                                variant="outlined"
                            >
                                {t('newVisit.common.clear')}
                            </Button>
                        </Box>
                        {signatureErrors.firmaResponsable && (
                            <Typography variant="body2" color="error">
                                {t('newVisit.enqueue.signatureError')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>

            {(selectedVisita?.nombreTipoVisita === 'VEROSIMILITUD' ||
                selectedVisita?.nombreTipoVisita === 'RGRL') && (
                <Box
                    component="div"
                    justifyContent="center"
                    sx={{ mt: 4, backgroundColor: 'white', p: 2 }}
                >
                    <Typography variant="h3">
                        <strong>{t('newVisit.aditionalData')}</strong>
                    </Typography>

                    <Grid container spacing={1} sx={{ p: 2 }}>
                        {selectedVisita?.nombreTipoVisita === 'VEROSIMILITUD' && (
                            <Grid item xs={12}>
                                <Box
                                    display="grid"
                                    gridTemplateColumns="20% 10% auto"
                                    alignItems="center"
                                    gap={2}
                                >
                                    <Typography>Verosimilitud</Typography>
                                    <VisitaEstadoChip
                                        estadoId={
                                            isVerosimilitudDraft
                                                ? VisitaEstado.BORRADOR
                                                : selectedVisita.idEstado
                                        }
                                    />

                                    <Box display="flex" gap={1}>
                                        {isVerosimilitudFinalizada && (
                                            <IconButton>
                                                <PictureAsPdfIcon />
                                            </IconButton>
                                        )}

                                        {isVerosimilitudDraft && (
                                            <>
                                                <IconButton>
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </>
                                        )}

                                        {shouldShowVerosimilitudRow && (
                                            <IconButton onClick={handleOpenVerosimilitud}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        )}

                        {selectedVisita?.nombreTipoVisita === 'RGRL' && (
                            <Grid item xs={12} lg={12}>
                                {loadingPresentaciones ? (
                                    <Box display="flex" alignItems="center" gap={2}>
                                        <CircularProgress size={24} />
                                    </Box>
                                ) : rgrlPresentaciones.length === 0 || allPresentationsCancelled ? (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns="20% 60% 20%"
                                        alignItems="center"
                                        gap={2}
                                        sx={{
                                            borderBottom: '1px solid #ccc'
                                        }}
                                    >
                                        <Typography>RGRL</Typography>
                                        <Box>
                                            <PresentationStateChip
                                                presentationState={
                                                    PRESENTATION_STATE.PENDIENTE_RGRL
                                                }
                                            />
                                        </Box>
                                        <Box display="flex">
                                            <IconButton onClick={handleOpenDialogRGRL}>
                                                <EditIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ) : (
                                    rgrlPresentaciones
                                        .filter((presentacion: any) => presentacion.idEstado !== 6)
                                        .map((presentacion: any) => {
                                            const showIcon = ![2, 3, 4, 5].includes(
                                                presentacion.idEstado
                                            );

                                            return (
                                                <Box
                                                    key={presentacion.id}
                                                    display="grid"
                                                    gridTemplateColumns="20% 60% 20%"
                                                    alignItems="center"
                                                    gap={2}
                                                    mb={1}
                                                    sx={{
                                                        borderBottom: '1px solid #ccc',
                                                        p: '15px'
                                                    }}
                                                >
                                                    <Typography>RGRL</Typography>
                                                    <Box>
                                                        <PresentationStateChip
                                                            presentationState={
                                                                presentacion.idEstado
                                                            }
                                                        />
                                                    </Box>
                                                    <Box display="flex" gap={1}>
                                                        {presentacion.idEstado ===
                                                        PRESENTATION_STATE.BORRADOR ? (
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleEditRGRL(presentacion)
                                                                }
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        ) : (
                                                            showIcon && (
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleCardClickRGRL(
                                                                            presentacion.tipo.id,
                                                                            presentacion.tipo.nombre
                                                                        )
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            )
                                                        )}

                                                        <IconButton
                                                            onClick={() =>
                                                                confirmCancelPresentation(
                                                                    presentacion.id
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            );
                                        })
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, p: 2 }}>
                <Button variant="outlined" color="primary" onClick={() => navigate(-1)}>
                    {t('buttons.cancel')}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={confirmSubmit}
                    disabled={!isFormValid}
                >
                    {t('newVisit.common.send')}
                </Button>
            </Box>

            <DrawerVisitas
                open={openDrawer}
                setOpen={setOpenDrawer}
                actionType="Establecimiento"
                visita={selectedVisitaDetail}
            />
            <DialogNewRGRL
                open={openDialogRGRL}
                handleClose={handleCloseDialogRGRL}
                handleCardClick={() => {}}
                handleCardClickRGRL={handleCardClickRGRL}
            />
        </>
    );
}

export default EjecutionsVisitsIndex;
