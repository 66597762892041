import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { Visita } from '../consts/visitasTypes';

interface VisitDetailsProps {
    visita: Visita;
}



const VisitDetails: React.FC<VisitDetailsProps> = ({ visita }) => {
    const { t } = useTranslation('visitas');
    return (
        <Alert
            severity="info"
            sx={{
                backgroundColor: '#E3F2FD',
                borderLeft: '5px solid #175AB6',
                padding: 2,
                fontWeight: 600,
                mt: 5,
                mb: 5
            }}
            icon={<InfoOutlinedIcon sx={{ color: '#175AB6' }} />}
        >
            <AlertTitle sx={{ fontWeight: 600 }}>{t('drawer.info.alertTitle')}</AlertTitle>

            <Typography variant="h6">
                <strong>{t('drawer.info.contract')}:</strong> {visita.nroContrato}
            </Typography>
            <Typography variant="h6">
                <strong>{t('drawer.info.organism')}:</strong> {visita.organismo}
            </Typography>
            <Typography variant="h6">
                <strong>{t('drawer.info.establishment')}:</strong> {visita.nombreEstablecimiento}
            </Typography>
            <Typography variant="h6">
                <strong>{t('drawer.info.city')}:</strong> {visita.city}
            </Typography>
            <Typography variant="h6">
                <strong>{t('drawer.info.zipCode')}:</strong> {visita.zipCode}
            </Typography>
            <Typography variant="h6">
                <strong>{t('drawer.info.visitType')}:</strong> {visita.nombreTipoVisita}
            </Typography>
            <Typography variant="h6">
                <strong>{t('drawer.info.deadline')}:</strong> {visita.fechaLimite}
            </Typography>
            <Typography variant="h6">
                <strong>{t('drawer.info.observations')}:</strong>{' '}
                {visita.observacion || t('drawer.noObservations')}
            </Typography>
        </Alert>
    );
};

export default VisitDetails;
