import React from 'react';
import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';

interface EstablishmentDetailsProps {
    establishment: any;
    loading: boolean;
}

const InfoDetailText = ({ label, value }: { label: string; value: string }) => (
    <Typography variant="body2">
        <strong>{label}: </strong>
        <span>{value || '-'}</span>
    </Typography>
);

const EstablishmentDetails: React.FC<EstablishmentDetailsProps> = ({ establishment, loading }) => {
    const { t } = useTranslation('establishments');
    if (loading) {
        return <CircularProgress size="1.5em" color="inherit" />;
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Card>
                <CardContent sx={{ paddingLeft: 4 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {t('info.sectionTitles.establishmentData')}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InfoDetailText
                                label={t('info.establishmentDataFields.name')}
                                value={establishment?.nombre || '-'}
                            />
                            <InfoDetailText
                                label={t('info.additionalFields.email')}
                                value={establishment?.email || '-'}
                            />
                            <InfoDetailText
                                label={t('info.additionalFields.phone')}
                                value={establishment?.telefono || '-'}
                            />
                            <InfoDetailText
                                label={t('info.establishmentDataFields.srtCode')}
                                value={establishment?.codigoSRT || '-'}
                            />
                            <InfoDetailText
                                label={t('info.additionalFields.numberOfEmployees')}
                                value={establishment?.personal?.toString() || '-'}
                            />
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 2, mb: 2 }} />

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {t('info.sectionTitles.domicileData')}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InfoDetailText
                                label={t('info.addressFields.street')}
                                value={establishment?.direccion?.calle || '-'}
                            />
                            <InfoDetailText
                                label={t('info.addressFields.streetNumber')}
                                value={establishment?.direccion?.altura || '-'}
                            />
                            <InfoDetailText
                                label={t('info.addressFields.apartmentFloor')}
                                value={establishment?.direccion?.piso || '-'}
                            />
                            <InfoDetailText
                                label={t('info.addressFields.apartment')}
                                value={establishment?.direccion?.departamento || '-'}
                            />
                            <InfoDetailText
                                label={t('info.addressFields.latitude')}
                                value={establishment?.direccion?.latitud || '-'}
                            />
                            <InfoDetailText
                                label={t('info.addressFields.longitude')}
                                value={establishment?.direccion?.longitud || '-'}
                            />
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 2, mb: 2 }} />

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {t('info.sectionTitles.organismData')}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InfoDetailText
                                label={t('getDataBySRT.fields.organism')}
                                value={establishment?.organismo?.nombre || '-'}
                            />
                            <InfoDetailText
                                label={t('getDataBySRT.fields.cuit')}
                                value={establishment?.organismo?.cuit || '-'}
                            />
                            <InfoDetailText
                                label={t('getDataBySRT.fields.contract')}
                                value={establishment?.organismo?.nroContrato || '-'}
                            />
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 2, mb: 2 }} />

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {t('info.sectionTitles.observations')}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InfoDetailText
                                label={t('info.additionalFields.observations')}
                                value={establishment?.observaciones || '-'}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};

export default EstablishmentDetails;
