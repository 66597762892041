import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout, tokenReceived } from '../features/auth/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { CreateVisitaRequest, VisitaResponse } from '../pages/Visitas/consts/visitasTypes';
import { transformVisitasResponse } from '../pages/Visitas/consts/visitasTransform';

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();
        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const visitasApi = createApi({
    reducerPath: 'visitas',
    tagTypes: ['Visitas'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        createVisita: builder.mutation<VisitaResponse, CreateVisitaRequest>({
            query: (body) => ({
                url: `/visitas`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Visitas']
        }),
        getVisitas: builder.query({
            query: (body) => ({
                url: `/visitas`,
                method: 'GET',
                params: body
            }),
            providesTags: ['Visitas'],
            transformResponse: transformVisitasResponse
        }),

        getVisitasIndicators: builder.query({
            query: () => ({
                url: `/visitas/indicadores`,
                method: 'GET'
            }),
            providesTags: ['Visitas'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        getTiposVisitas: builder.query({
            query: () => ({
                url: `/visitas/tiposVisitas`,
                method: 'GET'
            }),
            providesTags: ['Visitas']
        }),
        patchVisitaEstado: builder.mutation<
            void,
            { idVisita: number; estado: number; observacion: string }
        >({
            query: ({ idVisita, estado, observacion }) => ({
                url: `/visitas/${idVisita}`,
                method: 'PATCH',
                body: { estado, observacion }
            }),
            invalidatesTags: ['Visitas']
        }),
        patchEjecutionVisit: builder.mutation<
            void,
            {
                idVisita: number;
                esFallida: boolean;
                totalPersonal: number | null;
                administrativos: number | null;
                personalProduccion: number | null;
                seguridadHigiene: boolean | null;
                profesional: string;
                matricula: string;
                idTipoVisita: number | null;
                IdSubTipoVisita: number | null;
                observacion: string;
                firmaPreventor: string;
                firmaResponsable: string;
                estado: number;
            }
        >({
            query: ({ idVisita, ...rest }) => {
                const filteredBody = rest.esFallida
                    ? {
                          esFallida: rest.esFallida,
                          observacion: rest.observacion,
                          firmaPreventor: rest.firmaPreventor
                      }
                    : rest;

                return {
                    url: `/visitas/${idVisita}`,
                    method: 'PATCH',
                    body: filteredBody
                };
            },
            invalidatesTags: ['Visitas']
        }),
        patchVerosimilitudData: builder.mutation<
            void,
            {
                idVisita: number;
                idPreventor: number;
                observacion: string;
                idPresentacion: number;
                respuestas: { idSeccion: number; idTipoRepuesta: number }[];
            }
        >({
            query: ({ idVisita, ...body }) => ({
                url: `/visitas/verosimilitud/${idVisita}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Visitas']
        }),

        patchVerosimilitudVisit: builder.mutation<
            void,
            {
                idVisita: number;
                respuestas: { idSeccion: number; idTipoRepuesta: number }[];
            }
        >({
            query: ({ idVisita, ...body }) => ({
                url: `/visitas/${idVisita}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Visitas']
        })
    })
});

export const {
    useCreateVisitaMutation,
    useGetVisitasQuery,
    useLazyGetVisitasQuery,
    useGetVisitasIndicatorsQuery,
    useGetTiposVisitasQuery,
    usePatchVisitaEstadoMutation,
    usePatchEjecutionVisitMutation,
    usePatchVerosimilitudVisitMutation,
    usePatchVerosimilitudDataMutation
} = visitasApi;
