import { Avatar, Box, Card, CardHeader, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../features/slices/applicationSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useLogoutMutation } from '../../features/auth/authApi';
import { handleErrors } from '../../utils/common';
import { logout } from '../../features/auth/authSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function UserCardHeader({ isMobile, handleOpenNavMenu, user, role }: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [userLogout, { isLoading: loginOut }] = useLogoutMutation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation('layoutT');
    const getInitials = () => {
        const values = user?.nombre?.split(' ');
        let initials: string = '';
        values?.forEach((i: any) => {
            if (i) {
                initials += i.charAt(0).toUpperCase();
            }
        });
        return initials;
    };
    const logoutHandler = () => {
        dispatch(
            showAlert({
              title: t('userMenu.confirmLogout'),
              cancelText: t('common.cancel'),
              icon: 'info',
              confirmAction: handleLogOut
            })
          );          
    };

    const handleLogOut = async () => {
        const data = {
            username: user?.usuario
        };
        const response: any = await userLogout(data);
        if (response?.error) {
            handleErrors(response?.error);
        } else {
            dispatch(logout());
            navigate('/login');
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Card
            sx={{
                display: 'flex',
                maxWidth: 345,
                '& .MuiCardHeader-root': {
                    padding: '8px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textTransform: 'capitalize',
                    float: 'right'
                },
                '& .MuiCardHeader-subheader': {
                    textTransform: 'capitalize',
                    maxWidth: '200px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontSize: '10px',
                    float: 'right'
                },
                '& .MuiCardHeader-avatar': {
                    marginRight: isMobile ? 0 : '16px'
                }
            }}
        >
            
            <CardHeader
                title={isMobile ? null : `${user?.nombre || ""} ${user?.apellido || ""}`}
                subheader={isMobile ? null : role + `${user?.roles[0].nombre}`}
            />

            <CardHeader
               avatar={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    gap: '4px'
                  }}
                  onClick={(e) => {
                    if (isMobile) {
                      handleMenuClick(e);
                    } else {
                      navigate('usuarios/perfil');
                    }
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: 'var(--primary)',
                      fontSize: getInitials()?.length > 2 ? '17px' : '20px'
                    }}
                    aria-label="avatar"
                  >
                    {getInitials()?.substring(0, 3)}
                  </Avatar>
                  {isMobile && <KeyboardArrowDownIcon fontSize="small" />}
                </Box>
              }
              
            />


            {!isMobile && (
                <CardHeader
                    action={
                        <IconButton onClick={logoutHandler}>
                            <LogoutIcon />
                        </IconButton>
                    }
                />
            )}

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem
                    onClick={() => {
                        navigate('usuarios/perfil');
                        handleMenuClose();
                    }}
                >                  
               <ListItemText primary={t('userMenu.viewProfile')} />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        logoutHandler();
                    }}
                >
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('userMenu.logout')} />
                </MenuItem>
            </Menu>

        </Card>
    );
}

export default UserCardHeader;
