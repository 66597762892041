import { hideAlert } from '../../features/slices/applicationSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useIsMobile } from '../../hooks/useIsMobile';
import Button from '@mui/material/Button';
import CancelAlert from '../../assets/icons/cancelAlert.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoAlert from '../../assets/icons/infoAlert.png';
import ReactDOM from 'react-dom';
import styles from './styles.module.css';
import SuccessAlert from '../../assets/icons/successAlert.png';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, IconButton } from '@mui/material';
import ErrorAlert from '../../assets/icons/ErrorAlert.png';

interface AlertProps {
    title?: string;
    message?: string;
    buttonText?: string;
    customButtons?: JSX.Element | null;
    confirmAction?: (value: any) => any;
    cancelAction?: (value: any) => any;
    closeAction?: (value: any) => any;
    icon?: string | undefined;
    itemData?: any;
    confirmText?: string;
    cancelText?: string;
    reactElement?: any;
    keepMounted?: boolean;
    loading?: boolean;
}
export function AlertDialog(props: AlertProps) {
    const {
        title,
        message,
        keepMounted = false,
        reactElement,
        confirmAction,
        cancelAction,
        closeAction,
        itemData,
        customButtons,
        confirmText,
        cancelText,
        icon,
        loading
    } = props;

    const RenderAnimation = () => {
        const iconMap: { [key: string]: string } = {
            info: InfoAlert,
            success: SuccessAlert,
            cancel: CancelAlert,
            error: ErrorAlert
        };

        const selectedIcon = icon ? iconMap[icon] : iconMap['success'];

        return <img src={selectedIcon} alt={icon} style={{ height: 80, marginTop: 30 }} />;
    };

    const dispatch = useAppDispatch();
    const { isMobile } = useIsMobile();

    const handleCloseAction = () => {
        handleClose();

        if (closeAction) return closeAction(itemData);
    };

    const handleClose = () => {
        dispatch(hideAlert());
    };

    const handleAction = () => {
        handleClose();

        if (confirmAction) return confirmAction(itemData);
    };

    return (
        <Dialog
            className={styles.dialogstyle}
            open={!!props}
            onClose={
                keepMounted
                    ? () => {
                          return;
                      }
                    : handleClose
            }
            aria-labelledby="alert-agp"
            data-testid="alert-dialog-component"
            fullWidth={true}
            disableEscapeKeyDown
            sx={{
                textAlign: 'center',
                borderRadius: '8px',
                '& .MuiPaper-root': {
                    borderRadius: '8px',
                    width: 'auto',
                    minWidth: isMobile ? 'auto' : '440px',
                    padding: '30px 20px'
                },
                '& .MuiDialogActions-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isMobile ? '10px' : '20px',
                    alignItems: 'center',
                    '& button': {
                        padding: '10px 20px',
                        borderRadius: '4px',
                        textTransform: 'none',
                        fontSize: '14px',
                        width: '100%'
                    }
                    },
                '& .MuiIconButton-root': {
                    margin: '10px 20px'
                },
                '& .MuiDialogTitle-root': {
                    overflowWrap: 'break-word',
                    fontSize: '20px',
                    fontWeight: '700',
                    paddingBottom: '8px'
                },
                '& .MuiDialogContent-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center'
                },
                '& .MuiDialogContentText-root': {
                    padding: isMobile ? '0 4px' : '0 14px',
                    marginTop: '0',
                    overflowWrap: 'break-word',
                    lineHeight: '22px',
                    fontSize: isMobile ? '15px' : '17px',
                    alignSelf: 'center',
                    maxWidth: '540px'
                    }

            }}
        >
            <div className={styles['alert-container']}>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseAction}
                    sx={{
                        position: 'absolute',
                        right: 2,
                        top: 0,
                        color: '#1E272E'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <RenderAnimation />
                <DialogTitle
                    data-testid="alert-dialog-title"
                    id="alert-dialog-title"
                    className={styles.title}
                >
                    {title}
                </DialogTitle>
                {message && (
                    <DialogContent
                        data-testid={'alert-dialog-message'}
                        style={{
                            WebkitUserModify: 'read-write-plaintext-only'
                        }}
                    >
                        <DialogContentText>{message}</DialogContentText>
                    </DialogContent>
                )}
                {reactElement && (
                    <DialogContent data-testid={'alert-dialog-content'}>
                        {reactElement}
                    </DialogContent>
                )}
                {loading && (
                    <CircularProgress style={{ margin: '0 10px' }} color="inherit" size="1.5em" />
                )}
                <DialogActions data-testid={'alert-dialog-actions'}>
                    {customButtons ? (
                        customButtons
                    ) : (
                        <Box
                            display="flex"
                            flexDirection={isMobile ? 'column-reverse' : 'row'}
                            gap={isMobile ? 1 : 2.3}
                            width="100%"
                            >
                            {cancelText && (
                                <Button
                                    disabled={loading}
                                    variant="outlined"
                                    onClick={cancelAction || handleClose}
                                >
                                    {cancelText}
                                </Button>
                            )}
                            <Button
                                className={styles.button}
                                onClick={handleAction}
                                variant="contained"
                                disabled={loading}
                            >
                                {confirmText || 'Aceptar'}
                            </Button>
                        </Box>
                    )}
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default function Alert() {
    const propsRedux = useAppSelector((state) => state.application.alert);
    const alertElement = document.getElementById('alert')!;
    if (Object.keys(propsRedux).length < 1) return null;
    return ReactDOM.createPortal(<AlertDialog {...propsRedux} />, alertElement);
}
