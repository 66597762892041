import { getDate } from '../../../utils/common';
import { VisitaEstado, VisitStatus } from './visitasStatus';

export const transformVisitasResponse = (response: { data: any }) => ({
    data: {
        totalCount: response.data.totalCount,
        take: response?.data?.take,
        skip: response?.data?.skip,
        count: response?.data?.count,
        data: response?.data.data.map((visita: any) => ({
            id: visita?.id,
            nombreTipoVisita: visita?.tipoVisita?.descripcion,
            idTipoVisita: visita?.tipoVisita?.id,
            city: visita?.establecimiento?.direccion?.localidad
                ? visita?.establecimiento?.direccion?.localidad?.nombre
                : '-',
            zipCode: visita?.establecimiento?.direccion?.codigoPostal?.codigo || '-',
            fechaLimite: getDate(visita?.fechaLimite),
            fechaVisita: getDate(visita?.fechaVisita),
            idEstado: visita?.idEstado,
            nombreEstado: VisitStatus[visita?.idEstado as VisitaEstado] ?? '-',
            nombrePreventor: visita?.preventor?.nombre,
            firmaPreventor: visita?.firmaPreventor,
            firmaResponsable: visita?.firmaResponsable,
            idEstablecimiento: visita?.establecimiento?.id,
            nroContrato: visita?.establecimiento?.organismo?.organismoCentral?.nombre,
            organismo: visita?.establecimiento?.organismo.nombre,
            nombreEstablecimiento: visita?.establecimiento?.nombre,
            observacion: visita?.observacion,
            verosimilitudRespuestas: visita?.verosimilitudRespuestas
        }))
    }
});
