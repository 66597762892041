import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PresentationUploadErrorLocationData = Partial<Record<string, string[]>> | null;

interface InitialState {
  presentationUploadErrorLocationData: PresentationUploadErrorLocationData;
}

const initialState: InitialState = {
  presentationUploadErrorLocationData: null,
};

const srtPresentationsSlice = createSlice({
  name: 'srtPresentations',
  initialState,
  reducers: {
    setPresentationUploadErrorLocationData: (
      state,
      action: PayloadAction<PresentationUploadErrorLocationData>
    ) => {
      state.presentationUploadErrorLocationData = action.payload;
    },
    clearPresentationUploadErrorLocationData: (state) => {
      state.presentationUploadErrorLocationData = null;
    },
  },
});

export const { setPresentationUploadErrorLocationData, clearPresentationUploadErrorLocationData } =
  srtPresentationsSlice.actions;
export default srtPresentationsSlice.reducer;
