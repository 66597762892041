import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { showAlert, hideAlert } from '../../../../features/slices/applicationSlice';
import {
    usePatchVerosimilitudDataMutation,
    usePatchVerosimilitudVisitMutation
} from '../../../../services/visitasApi';
import { useTranslation } from 'react-i18next';
const useVerosimilitudHandler = (
    idVisita: number,
    idPreventor: number,
    observacion: string,
    idPresentacion: number,
    selectedPresentationId: number
) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [patchVerosimilitud] = usePatchVerosimilitudVisitMutation();
    const [patchVerosimilitudData] = usePatchVerosimilitudDataMutation();
    const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(false);
    const { t } = useTranslation('visitas');
    const handleExit = () => {
        dispatch(
            showAlert({
                title: t('verosimilitud.alerts.exitTitle'),
                message: t('verosimilitud.alerts.exitMessage'),
                cancelText: t('verosimilitud.alerts.exitCancelText'),
                confirmText: t('verosimilitud.alerts.exitConfirmText'),
                icon: 'info',
                cancelAction: handleGuardarBorrador,
                confirmAction: () => {
                    localStorage.removeItem('verosimilitud');
                    navigate(-1);
                }
            })
        );
    };

    const mapVerosimilitudToRespuestas = (): {
        idSeccion: number;
        idTipoRepuesta: number;
    }[] => {
        const data = JSON.parse(localStorage.getItem('verosimilitud') || '[]');
        return data.map((item: any) => {
            const selected = item.opciones.find((o: any) => o.selected);
            return {
                idSeccion: item.idSeccion,
                idTipoRepuesta: selected?.id ?? 3
            };
        });
    };

    const handleGuardarBorrador = () => {
        dispatch(
            showAlert({
                title: t('verosimilitud.alerts.saveDraftTitle'),
                cancelText: t('verosimilitud.alerts.saveDraftCancelText'),
                confirmText: t('verosimilitud.alerts.saveDraftConfirmText'),
                icon: 'info',
                confirmAction: handleConfirmGuardarBorrador
            })
        );
    };

    const handleConfirmGuardarBorrador = async () => {
        setIsLoadingResponse(true);

        const respuestas = mapVerosimilitudToRespuestas();

        const payload = {
            idVisita,
            idPreventor,
            observacion,
            respuestas,
            idPresentacion: selectedPresentationId
        };

        try {
            await patchVerosimilitudData(payload).unwrap();
            dispatch(
                showAlert({
                    title: t('verosimilitud.alerts.saveSuccessTitle'),
                    icon: 'success',
                    confirmText: t('verosimilitud.alerts.saveSuccessConfirmText'),
                    confirmAction: () => navigate(-1),
                    closeAction: () => navigate(-1)
                })
            );
        } catch (error: any) {
            dispatch(
                showAlert({
                    title: t('verosimilitud.alerts.saveErrorTitle'),
                    message: error?.data?.message || t('verosimilitud.alerts.saveErrorMessage'),
                    icon: 'error',
                    confirmText: t('verosimilitud.alerts.saveErrorConfirmText')
                })
            );
        } finally {
            setIsLoadingResponse(false);
        }
    };

    const handleEnviarPresentacion = () => {
        dispatch(
            showAlert({
                title: t('verosimilitud.alerts.submitTitle'),
                message: t('verosimilitud.alerts.submitMessage'),
                cancelText: t('verosimilitud.alerts.submitCancelText'),
                confirmText: t('verosimilitud.alerts.submitConfirmText'),
                icon: 'info',
                confirmAction: handleConfirmEnviarPresentacion
            })
        );
    };

    const handleConfirmEnviarPresentacion = async () => {
        setIsLoadingResponse(true);
        const respuestas = mapVerosimilitudToRespuestas();

        const payload = {
            idVisita,
            respuestas
        };

        try {
            await patchVerosimilitud(payload).unwrap();
            dispatch(
                showAlert({
                    title: t('verosimilitud.alerts.submitSuccessTitle'),
                    icon: 'success',
                    confirmText: t('verosimilitud.alerts.submitSuccessConfirmText'),
                    confirmAction: () => navigate(-1),
                    closeAction: () => navigate(-1)
                })
            );
            localStorage.removeItem('verosimilitud');
        } catch (error: any) {
            dispatch(
                showAlert({
                    title: t('verosimilitud.alerts.submitErrorTitle'),
                    message: error?.data?.message || t('verosimilitud.alerts.submitErrorMessage'),
                    icon: 'error',
                    confirmText: t('verosimilitud.alerts.submitErrorConfirmText')
                })
            );
        } finally {
            setIsLoadingResponse(false);
        }
    };

    return {
        handleExit,
        handleGuardarBorrador,
        handleEnviarPresentacion,
        handleConfirmGuardarBorrador,
        handleConfirmEnviarPresentacion,
        isLoadingResponse
    };
};

export default useVerosimilitudHandler;
