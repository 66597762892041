import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Card,
    CardContent,
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ChevronRightOutlined } from '@mui/icons-material';
import { useGetTiposPresentacionesQuery } from '../../../services/presentacionesApi';
import { useTranslation } from 'react-i18next';

const DialogNewRGRL = ({
    handleClose,
    open,
    handleCardClick,
    handleCardClickRGRL
}: {
    open: boolean;
    handleClose: () => void;
    handleCardClick: (id: number) => void;
    handleCardClickRGRL: (id: number, titulo: string) => void;
}) => {
    const { t } = useTranslation('presentaciones');
    const { data: tiposPresentaciones = [], isLoading, isError } = useGetTiposPresentacionesQuery();

    const tiposRGRL = tiposPresentaciones.filter((tipo: any) => tipo.nombre === 'RGRL');

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '700px',
                    backgroundColor: '#F3F8F8'
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'white' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {t('presentaciones.title')}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ borderTop: 'none' }}>
                {isLoading ? (
                    <div>{t('loading')}</div>
                ) : isError ? (
                    <div>{t('errorLoading')}</div>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Accordion sx={{ boxShadow: 'none' }}>
                            <AccordionSummary
                                sx={{ background: 'white', color: '#000000', borderRadius: 150 }}
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Box>
                                    <Typography variant="h4">{t('types.rgrl')}</Typography>
                                    <Typography variant="body2">
                                        {t('types.rgrl_description')}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                {tiposRGRL.map((tipo: any) => (
                                    <Card
                                        key={tipo.id}
                                        sx={{ backgroundColor: 'white', cursor: 'pointer' }}
                                        onClick={() => handleCardClickRGRL(tipo.id, tipo.titulo)}
                                    >
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Box>
                                                    <Typography variant="h4">
                                                        {tipo.titulo}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {tipo.detalle}
                                                    </Typography>
                                                </Box>
                                                <UploadFileOutlinedIcon
                                                    sx={{
                                                        color: '#384A52',
                                                        border: '1px solid #E0EBED',
                                                        fontSize: '36px',
                                                        padding: '3px',
                                                        borderRadius: '5px',
                                                        margin: '5px'
                                                    }}
                                                />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export { DialogNewRGRL };
