import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { logout, tokenReceived } from '../features/auth/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});

export interface CreateOrganismRequest {
    razonSocial: string;
    cuit: string;
    polizaSRT: number;
    idCIIU: number;
    idOrganismoCentral: number;
    nroContrato: string;
}

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();

        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const organismsApi = createApi({
    reducerPath: 'organisms',
    tagTypes: ['Organismos', 'OrganismoById'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        createOrganism: builder.mutation({
            query: (body: any) => ({
                url: 'organismos',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['Organismos']
        }),
        editOrganism: builder.mutation({
            query: ({ body, organismId }: any) => ({
                url: `organismos/${organismId}`,
                method: 'PATCH',
                body: body
            }),
            invalidatesTags: ['Organismos']
        }),
        getOrganisms: builder.query({
            query: (params) => ({
                url: `organismos`,
                params
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Organismos']
        }),
        deregisterOrganism: builder.mutation({
            query: ({ body, organismId }: any) => ({
                url: `organismos/${organismId}/estado`,
                method: 'PATCH',
                body: body
            }),
            invalidatesTags: ['Organismos']
        }),
        getOrganismContractFile: builder.query({
            query: ({ idOrganismo }) => ({
                url: `organismos/${idOrganismo}/archivo`
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Organismos']
        }),
        getCentralOrganisms: builder.query({
            query: () => ({
                url: 'organismos/organismos-central',
                method: 'GET'
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Organismos']
        })
    })
});

export const {
    useCreateOrganismMutation,
    useEditOrganismMutation,
    useDeregisterOrganismMutation,
    useGetOrganismsQuery,
    useLazyGetOrganismContractFileQuery,
    useGetCentralOrganismsQuery
} = organismsApi;
