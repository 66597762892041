enum FeatureKeys {
    GESTION_USUARIOS = 'GESTION_USUARIOS',
    ALTA_USUARIO = 'ALTA_USUARIO',
    EDICION_USUARIO = 'EDICION_USUARIO',
    CONSULTA_USUARIO = 'CONSULTA_USUARIO',
    GESTION_ROLES = 'GESTION_ROLES',
    ALTA_ROLES = 'ALTA_ROLES',
    EDICION_ROLES = 'EDICION_ROLES',
    CONSULTA_ROLES = 'CONSULTA_ROLES',
    GESTION_ESTABLECIMIENTOS = 'GESTION_ESTABLECIMIENTOS',
    ALTA_ESTABLECIMIENTOS = 'ALTA_ESTABLECIMIENTOS',
    EDICION_ESTABLECIMIENTOS = 'EDICION_ESTABLECIMIENTOS',
    CONSULTA_ESTABLECIMIENTOS = 'CONSULTA_ESTABLECIMIENTOS',
    SOLICITUD_BAJA_ESTABLECIMIENTO = 'SOLICITUD_BAJA_ESTABLECIMIENTO',
    APROBACION_SOLICITUD_ESTABLECIMIENTO = 'APROBACION_SOLICITUD_ESTABLECIMIENTO',
    DESCARGA_EXCEL_ESTABLECIMIENTO = 'DESCARGA_EXCEL_ESTABLECIMIENTO',
    ASIGNACION_ESTABLECIMENTO_USUARIO = 'ASIGNACION_ESTABLECIMENTO_USUARIO',
    GESTION_PRESENTACIONES = 'GESTION_PRESENTACIONES',
    ALTA_PRESENTACION = 'ALTA_PRESENTACION',
    EDICION_PRESENTACION = 'EDICION_PRESENTACION',
    CONSULTA_PRESENTACION = 'CONSULTA_PRESENTACION',
    ELIMINACION_PRESENTACION = 'ELIMINACION_PRESENTACION',
    GESTION_ORGANISMOS = 'GESTION_ORGANISMOS',
    ALTA_ORGANISMOS = 'ALTA_ORGANISMOS',
    EDICION_ORGANISMOS = 'EDICION_ORGANISMOS',
    INACTIVACION_ORGANISMOS = 'INACTIVACION_ORGANISMOS',
    GESTION_NOMINA_EMPLEADOS = 'GESTION_NOMINA_EMPLEADOS',
    GESTION_CERTIFICADO_DE_COBERTURA = 'GESTION_CERTIFICADO_DE_COBERTURA',
    ASIGNACION_NOMINA_ESTABLECIMIENTO = 'ASIGNACION_NOMINA_ESTABLECIMIENTO',
    DESCARGA_NOMINA_EXCEL = 'DESCARGA_NOMINA_EXCEL',
    GESTION_PUESTOS_DE_TRABAJO = 'GESTION_PUESTOS_DE_TRABAJO',
    ALTA_PUESTO_DE_TRABAJO = 'ALTA_PUESTO_DE_TRABAJO',
    VISUALIZACION_DE_DETALLE_PRESENTACION = 'VISUALIZACION_DE_DETALLE_PRESENTACION',
    EDICION_PUESTO_DE_TRABAJO = 'EDICION_PUESTO_DE_TRABAJO',
    INACTIVACION_PUESTO_DE_TRABAJO = 'INACTIVACION_PUESTO_DE_TRABAJO',
    CAMBIAR_ESTADO_PRESENTACION = 'CAMBIAR_ESTADO_PRESENTACION',
    GESTION_DE_PRESENTACIONES_VINCULACION_RGRL = 'GESTION_DE_PRESENTACIONES_VINCULACION_RGRL',
    GENERACION_ARCHIVOS_SRT = 'GENERACION_ARCHIVOS_SRT',
    DESCARGA_PDF = 'DESCARGA_PDF',
    CARGA_RESPUESTA_SRT = 'CARGA_RESPUESTA_SRT',
    CONSULTA_DE_PRESENTACIONES_SRT = 'CONSULTA_DE_PRESENTACIONES_SRT',
    ALTA_DE_VISITAS = 'ALTA_DE_VISITAS',
    GESTION_DE_VISITAS = 'GESTION_DE_VISITAS',
    DESCARGA_DE_ACTA = ' DESCARGA_DE_ACTA',
    EDICION_ELIMINACION_RGRL_VEROSIMILITUD = ' EDICION_ELIMINACION_RGRL_VEROSIMILITUD',
    EDITAR_PRESENTACION_BO = 'EDITAR_PRESENTACION_BO'
}

interface Feature {
    url: string | null;
    hasAccess: boolean;
}

type Features = {
    [key in FeatureKeys]: Feature;
};

const GROUP_ID = {
    USUARIOS: 1,
    ADMINISTRACION: 2,
    ESTABLECIMIENTO: 3,
    PRESENTACIONES: 4,
    NOMINA: 5,
    PUESTOS: 6,
    ORGANISMOS: 7,
    VINCULACION_RGRL: 8,
    VISITAS: 10
};

const FUNCTIONALITY_ID = {
    GESTION_USUARIOS: 1,
    ALTA_USUARIO: 2,
    EDICION_USUARIO: 3,
    CONSULTA_USUARIO: 4,
    GESTION_ROLES: 5,
    ALTA_ROLES: 6,
    EDICION_ROLES: 7,
    CONSULTA_ROLES: 8,
    GESTION_ESTABLECIMIENTOS: 9,
    ALTA_ESTABLECIMIENTOS: 10,
    EDICION_ESTABLECIMIENTOS: 11,
    CONSULTA_ESTABLECIMIENTOS: 12,
    DESCARGA_EXCEL_ESTABLECIMIENTO: 35,
    ASIGNACION_ESTABLECIMENTO_USUARIO: 13,
    GESTION_PRESENTACIONES: 14,
    ALTA_PRESENTACION: 15,
    EDICION_PRESENTACION: 16,
    CONSULTA_PRESENTACION: 17,
    ELIMINACION_PRESENTACION: 18,
    GESTION_NOMINA_EMPLEADOS: 19,
    GESTION_CERTIFICADO_DE_COBERTURA: 20,
    ASIGNACION_NOMINA_ESTABLECIMIENTO: 21,
    GESTION_PUESTOS_DE_TRABAJO: 22,
    ALTA_PUESTO_DE_TRABAJO: 23,
    EDICION_PUESTO_DE_TRABAJO: 24,
    INACTIVACION_PUESTO_DE_TRABAJO: 25,
    DESCARGA_NOMINA_EXCEL: 26,
    GESTION_ORGANISMOS: 27,
    ALTA_ORGANISMOS: 28,
    EDICION_ORGANISMOS: 29,
    INACTIVACION_ORGANISMOS: 30,
    VISUALIZACION_DE_DETALLE_PRESENTACION: 31,
    SOLICITUD_BAJA_ESTABLECIMIENTO: 32,
    APROBACION_SOLICITUD_ESTABLECIMIENTO: 33,
    CAMBIAR_ESTADO_PRESENTACION: 34,
    GESTION_DE_PRESENTACIONES_VINCULACION_RGRL: 36,
    GENERACION_ARCHIVOS_SRT: 37,
    DESCARGA_PDF: 38,
    CARGA_RESPUESTA_SRT: 39,
    CONSULTA_DE_PRESENTACIONES_SRT: 42,
    ALTA_DE_VISITAS: 50,
    GESTION_DE_VISITAS: 51,
    DESCARGA_DE_ACTA: 52,
    EDICION_ELIMINACION_RGRL_VEROSIMILITUD: 53,
    EDITAR_PRESENTACION_BO: 54
};

interface Access {
    [key: number]: {
        [key: number]: {
            name: string;
            url: string;
            showInMenu: boolean;
        };
    };
}

const getAccessUrl = (
    access: Access | null,
    groupId: number,
    functionalityId: number
): string | null => {
    return access?.[groupId]?.[functionalityId]?.url || null;
};

const hasAccess = (access: any, groupId: number, functionalityId: number): boolean => {
    return !!access?.[groupId]?.[functionalityId];
};

const functionalities = [
    {
        groupId: GROUP_ID.USUARIOS,
        functionalityId: FUNCTIONALITY_ID.GESTION_USUARIOS,
        key: 'GESTION_USUARIOS'
    },
    {
        groupId: GROUP_ID.USUARIOS,
        functionalityId: FUNCTIONALITY_ID.ALTA_USUARIO,
        key: 'ALTA_USUARIO'
    },
    {
        groupId: GROUP_ID.USUARIOS,
        functionalityId: FUNCTIONALITY_ID.EDICION_USUARIO,
        key: 'EDICION_USUARIO'
    },
    {
        groupId: GROUP_ID.USUARIOS,
        functionalityId: FUNCTIONALITY_ID.CONSULTA_USUARIO,
        key: 'CONSULTA_USUARIO'
    },
    {
        groupId: GROUP_ID.ADMINISTRACION,
        functionalityId: FUNCTIONALITY_ID.GESTION_ROLES,
        key: 'GESTION_ROLES'
    },
    {
        groupId: GROUP_ID.ADMINISTRACION,
        functionalityId: FUNCTIONALITY_ID.ALTA_ROLES,
        key: 'ALTA_ROLES'
    },
    {
        groupId: GROUP_ID.ADMINISTRACION,
        functionalityId: FUNCTIONALITY_ID.EDICION_ROLES,
        key: 'EDICION_ROLES'
    },
    {
        groupId: GROUP_ID.ADMINISTRACION,
        functionalityId: FUNCTIONALITY_ID.CONSULTA_ROLES,
        key: 'CONSULTA_ROLES'
    },
    {
        groupId: GROUP_ID.ESTABLECIMIENTO,
        functionalityId: FUNCTIONALITY_ID.GESTION_ESTABLECIMIENTOS,
        key: 'GESTION_ESTABLECIMIENTOS'
    },
    {
        groupId: GROUP_ID.ESTABLECIMIENTO,
        functionalityId: FUNCTIONALITY_ID.ALTA_ESTABLECIMIENTOS,
        key: 'ALTA_ESTABLECIMIENTOS'
    },
    {
        groupId: GROUP_ID.ESTABLECIMIENTO,
        functionalityId: FUNCTIONALITY_ID.EDICION_ESTABLECIMIENTOS,
        key: 'EDICION_ESTABLECIMIENTOS'
    },
    {
        groupId: GROUP_ID.ESTABLECIMIENTO,
        functionalityId: FUNCTIONALITY_ID.SOLICITUD_BAJA_ESTABLECIMIENTO,
        key: 'SOLICITUD_BAJA_ESTABLECIMIENTO'
    },
    {
        groupId: GROUP_ID.ESTABLECIMIENTO,
        functionalityId: FUNCTIONALITY_ID.APROBACION_SOLICITUD_ESTABLECIMIENTO,
        key: 'APROBACION_SOLICITUD_ESTABLECIMIENTO'
    },
    {
        groupId: GROUP_ID.ESTABLECIMIENTO,
        functionalityId: FUNCTIONALITY_ID.CONSULTA_ESTABLECIMIENTOS,
        key: 'CONSULTA_ESTABLECIMIENTOS'
    },
    {
        groupId: GROUP_ID.ESTABLECIMIENTO,
        functionalityId: FUNCTIONALITY_ID.DESCARGA_EXCEL_ESTABLECIMIENTO,
        key: 'DESCARGA_EXCEL_ESTABLECIMIENTO'
    },
    {
        groupId: GROUP_ID.USUARIOS,
        functionalityId: FUNCTIONALITY_ID.ASIGNACION_ESTABLECIMENTO_USUARIO,
        key: 'ASIGNACION_ESTABLECIMENTO_USUARIO'
    },
    {
        groupId: GROUP_ID.PRESENTACIONES,
        functionalityId: FUNCTIONALITY_ID.GESTION_PRESENTACIONES,
        key: 'GESTION_PRESENTACIONES'
    },
    {
        groupId: GROUP_ID.PRESENTACIONES,
        functionalityId: FUNCTIONALITY_ID.CAMBIAR_ESTADO_PRESENTACION,
        key: 'CAMBIAR_ESTADO_PRESENTACION'
    },
    {
        groupId: GROUP_ID.PRESENTACIONES,
        functionalityId: FUNCTIONALITY_ID.ALTA_PRESENTACION,
        key: 'ALTA_PRESENTACION'
    },
    {
        groupId: GROUP_ID.PRESENTACIONES,
        functionalityId: FUNCTIONALITY_ID.EDICION_PRESENTACION,
        key: 'EDICION_PRESENTACION'
    },
    {
        groupId: GROUP_ID.PRESENTACIONES,
        functionalityId: FUNCTIONALITY_ID.EDITAR_PRESENTACION_BO,
        key: 'EDITAR_PRESENTACION_BO'
    },
    {
        groupId: GROUP_ID.PRESENTACIONES,
        functionalityId: FUNCTIONALITY_ID.VISUALIZACION_DE_DETALLE_PRESENTACION,
        key: 'VISUALIZACION_DE_DETALLE_PRESENTACION'
    },
    {
        groupId: GROUP_ID.PRESENTACIONES,
        functionalityId: FUNCTIONALITY_ID.CONSULTA_PRESENTACION,
        key: 'CONSULTA_PRESENTACION'
    },
    {
        groupId: GROUP_ID.PRESENTACIONES,
        functionalityId: FUNCTIONALITY_ID.ELIMINACION_PRESENTACION,
        key: 'ELIMINACION_PRESENTACION'
    },
    {
        groupId: GROUP_ID.ORGANISMOS,
        functionalityId: FUNCTIONALITY_ID.GESTION_ORGANISMOS,
        key: 'GESTION_ORGANISMOS'
    },
    {
        groupId: GROUP_ID.ORGANISMOS,
        functionalityId: FUNCTIONALITY_ID.ALTA_ORGANISMOS,
        key: 'ALTA_ORGANISMOS'
    },
    {
        groupId: GROUP_ID.ORGANISMOS,
        functionalityId: FUNCTIONALITY_ID.EDICION_ORGANISMOS,
        key: 'EDICION_ORGANISMOS'
    },
    {
        groupId: GROUP_ID.ORGANISMOS,
        functionalityId: FUNCTIONALITY_ID.INACTIVACION_ORGANISMOS,
        key: 'INACTIVACION_ORGANISMOS'
    },
    {
        groupId: GROUP_ID.NOMINA,
        functionalityId: FUNCTIONALITY_ID.GESTION_CERTIFICADO_DE_COBERTURA,
        key: 'GESTION_CERTIFICADO_DE_COBERTURA'
    },
    {
        groupId: GROUP_ID.NOMINA,
        functionalityId: FUNCTIONALITY_ID.GESTION_NOMINA_EMPLEADOS,
        key: 'GESTION_NOMINA_EMPLEADOS'
    },
    {
        groupId: GROUP_ID.NOMINA,
        functionalityId: FUNCTIONALITY_ID.ASIGNACION_NOMINA_ESTABLECIMIENTO,
        key: 'ASIGNACION_NOMINA_ESTABLECIMIENTO'
    },
    {
        groupId: GROUP_ID.NOMINA,
        functionalityId: FUNCTIONALITY_ID.DESCARGA_NOMINA_EXCEL,
        key: 'DESCARGA_NOMINA_EXCEL'
    },
    {
        groupId: GROUP_ID.PUESTOS,
        functionalityId: FUNCTIONALITY_ID.GESTION_PUESTOS_DE_TRABAJO,
        key: 'GESTION_PUESTOS_DE_TRABAJO'
    },
    {
        groupId: GROUP_ID.PUESTOS,
        functionalityId: FUNCTIONALITY_ID.ALTA_PUESTO_DE_TRABAJO,
        key: 'ALTA_PUESTO_DE_TRABAJO'
    },
    {
        groupId: GROUP_ID.PUESTOS,
        functionalityId: FUNCTIONALITY_ID.EDICION_PUESTO_DE_TRABAJO,
        key: 'EDICION_PUESTO_DE_TRABAJO'
    },
    {
        groupId: GROUP_ID.PUESTOS,
        functionalityId: FUNCTIONALITY_ID.INACTIVACION_PUESTO_DE_TRABAJO,
        key: 'INACTIVACION_PUESTO_DE_TRABAJO'
    },
    {
        groupId: GROUP_ID.VINCULACION_RGRL,
        functionalityId: FUNCTIONALITY_ID.GESTION_DE_PRESENTACIONES_VINCULACION_RGRL,
        key: 'GESTION_DE_PRESENTACIONES_VINCULACION_RGRL'
    },
    {
        groupId: GROUP_ID.VINCULACION_RGRL,
        functionalityId: FUNCTIONALITY_ID.GENERACION_ARCHIVOS_SRT,
        key: 'GENERACION_ARCHIVOS_SRT'
    },
    {
        groupId: GROUP_ID.VINCULACION_RGRL,
        functionalityId: FUNCTIONALITY_ID.DESCARGA_PDF,
        key: 'DESCARGA_PDF'
    },
    {
        groupId: GROUP_ID.VINCULACION_RGRL,
        functionalityId: FUNCTIONALITY_ID.CARGA_RESPUESTA_SRT,
        key: 'CARGA_RESPUESTA_SRT'
    },
    {
        groupId: GROUP_ID.VINCULACION_RGRL,
        functionalityId: FUNCTIONALITY_ID.CONSULTA_DE_PRESENTACIONES_SRT,
        key: 'CONSULTA_DE_PRESENTACIONES_SRT'
    },
    {
        groupId: GROUP_ID.VISITAS,
        functionalityId: FUNCTIONALITY_ID.ALTA_DE_VISITAS,
        key: 'ALTA_DE_VISITAS'
    },
    {
        groupId: GROUP_ID.VISITAS,
        functionalityId: FUNCTIONALITY_ID.GESTION_DE_VISITAS,
        key: 'GESTION_DE_VISITAS'
    },
    {
        groupId: GROUP_ID.VISITAS,
        functionalityId: FUNCTIONALITY_ID.DESCARGA_DE_ACTA,
        key: 'DESCARGA_DE_ACTA'
    },
    {
        groupId: GROUP_ID.VISITAS,
        functionalityId: FUNCTIONALITY_ID.EDICION_ELIMINACION_RGRL_VEROSIMILITUD,
        key: 'EDICION_ELIMINACION_RGRL_VEROSIMILITUD'
    }
];

const getAccessValues = (access: Access | null): Features => {
    const accessValues = {} as Features;
    functionalities.forEach(({ groupId, functionalityId, key }) => {
        accessValues[key as FeatureKeys] = {
            url: getAccessUrl(access, groupId, functionalityId),
            hasAccess: hasAccess(access, groupId, functionalityId)
        };
    });
    return accessValues;
};

export { GROUP_ID, FUNCTIONALITY_ID, getAccessUrl, hasAccess, getAccessValues };
