import { useEffect, useState } from 'react';
import useContracts from '../../../../components/layout/ContractSelector/hook/useContracts';
import {
    useLazyGetCitiesQuery,
    useGetEmpresasQuery,
    useLazyGetEmpresasQuery,
    useLazyGetPostalCodesQuery,
    useGetProvincesQuery
} from '../../../../services/companyApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { Dayjs } from 'dayjs';
import { showAlert } from '../../../../features/slices/applicationSlice';
import { enqueueSnackbar } from 'notistack';
import { useCreateVisitaMutation } from '../../../../services/visitasApi';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLazyGetPresentacionesByEstablecimientoQuery } from '../../../../services/presentacionesApi';

interface FormData {
    observaciones: string;
    nroContrato: { id: number; nombre: string; value: number } | null;
    idOrganismo: { id: number; nombre: string; value: number } | null;
    provincia: { id: number; nombre: string; value: number } | null;
    idProvincia: { id: number; nombre: string; value: number } | null;
    ciudad: { id: number; nombre: string; value: number } | null;
    codigoPostal: { id: number; nombre: string; value: number } | null;
    idTipoVisita: { id: number; nombre: string; value: number } | null;
    idPreventor: { id: number; nombre: string; value: number } | null;
    fechaLimite: Dayjs | null;
    idEstablecimientos: number[];
}

const formatDataForSubmission = (data: FormData) => ({
    observacion: data.observaciones,
    nroContrato: data.nroContrato?.id || 0,
    idOrganismo: data.idOrganismo?.id || 0,
    idProvincia: data.idProvincia || 0,
    ciudad: data.ciudad?.id || 0,
    codigoPostal: data.codigoPostal?.id || 0,
    idTipoVisita: data.idTipoVisita?.id || 0,
    idPreventor: data.idPreventor?.id || 0,
    fechaLimite: data.fechaLimite ? data.fechaLimite.toISOString() : '',
    idEstablecimientos: data.idEstablecimientos
});

function useCreateVisita() {
    const [step, setStep] = useState(1);
    const dispatch = useAppDispatch();
    const [createVisita, { isLoading }] = useCreateVisitaMutation();
    const { t } = useTranslation('visitas');
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>({
        observaciones: '',
        nroContrato: null,
        idOrganismo: null,
        provincia: null,
        idProvincia: null,
        ciudad: null,
        codigoPostal: null,
        idTipoVisita: null,
        idPreventor: null,
        fechaLimite: null,
        idEstablecimientos: []
    });
    const [getPresentacionesByEstablecimiento] = useLazyGetPresentacionesByEstablecimientoQuery();
    const { contracts, isFetching: isFetchingContracts } = useContracts();
    const userData = useAppSelector((state) => state.auth.user);
    const [organisms, setOrganisms] = useState(userData?.organismos || []);
    const [fetchEstablishments, { data: establishmentsData }] = useLazyGetEmpresasQuery();
    const [loadingValidacionRGRL, setLoadingValidacionRGRL] = useState(false);
    const [allOrganisms, setAllOrganisms] = useState(userData?.organismos || []);

    useEffect(() => {
        setAllOrganisms(userData?.organismos || []);
        setOrganisms(userData?.organismos || []);
    }, [userData?.organismos]);

    useEffect(() => {
        if (formData.idOrganismo?.id) {
            fetchEstablishments({
                idProvincia: [formData.idProvincia],
                localidad: formData.ciudad?.nombre,
                skip: 0,
                take: 1000
            });
        }
    }, [fetchEstablishments, formData.ciudad?.id, formData.idProvincia]);

    const establishments = Array.isArray(establishmentsData?.data?.data)
        ? establishmentsData.data?.data.map((est: any) => ({
              id: est.id,
              nombre: est.nombre,
              localidad: est.direccion?.localidad || 'N/A',
              postalCode: est.direccion?.postalCode || 'N/A'
          }))
        : [];

    const handleSelectEstablishment = (establishmentId: number) => {
        setFormData((prev) => ({
            ...prev,
            idEstablecimientos: prev.idEstablecimientos.includes(establishmentId)
                ? prev.idEstablecimientos.filter((id) => id !== establishmentId)
                : [...prev.idEstablecimientos, establishmentId]
        }));
    };

    const handleContractSelection = (value: any) => {
        const filteredOrganisms = value
            ? allOrganisms.filter((org) => org?.idOrganismoCentral === value?.id)
            : allOrganisms;

        if (!value?.id) {
            setFormData((prev) => ({
                ...prev,
                idOrganismo: null
            }));
            return;
        }
        setOrganisms(filteredOrganisms);
        handleChange({ target: { name: 'nroContrato', value } });
    };

    const { data: provincesList } = useGetProvincesQuery(null);
    const provinces =
        provincesList?.data?.provincias?.map((province: { id: number; nombre: string }) => ({
            nombre: province.nombre,
            id: province.id,
            value: province.id
        })) || [];

    const [getCities, { data: citiesList }] = useLazyGetCitiesQuery();
    useEffect(() => {
        if (formData.idProvincia) {
            getCities({ provinceId: formData.idProvincia });
        }
    }, [formData.idProvincia]);

    const cities = Array.isArray(citiesList?.data?.localidades)
        ? citiesList.data.localidades.map(
              (city: { id: number; nombre: string; codigoPostal: number }) => ({
                  nombre: city.nombre,
                  id: city.id,
                  value: city.id,
                  codigoPostal: city.codigoPostal
              })
          )
        : [];

    const handleChange = (event: { target: { name: any; value: any } }) => {
        const { name, value } = event.target;

        if (name === 'idProvincia') {
            setFormData((prev) => ({
                ...prev,
                idProvincia: value?.id || null,
                ciudad: null,
                codigoPostal: null,
                provincia: value
            }));
        } else if (name === 'ciudad') {
            setFormData((prev) => ({
                ...prev,
                ciudad: value,
                codigoPostal: value
                    ? {
                          id: value.id,
                          nombre: value.codigoPostal.toString(),
                          value: value.codigoPostal
                      }
                    : null
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleClearSelection = () => {
        setFormData((prev) => ({
            ...prev,
            idEstablecimientos: []
        }));
    };

    const handleSubmit = async () => {
        const formattedData = formatDataForSubmission(formData);
        const isVerosimilitud = formData.idTipoVisita?.id === 457;

        if (isVerosimilitud) {
            setLoadingValidacionRGRL(true);
            const establishmentsWithoutRgrl: string[] = [];

            for (const establishmentId of formData.idEstablecimientos) {
                try {
                    const response = await getPresentacionesByEstablecimiento({
                        idEstablecimiento: establishmentId,
                        skip: 0,
                        take: 1
                    }).unwrap();
                    const presentations = response?.data?.data || [];
                    const hasOnlyRar = presentations.every(
                        (presentation: { tipo: { id: number } }) => presentation.tipo?.id === 4
                    );

                    if (hasOnlyRar) {
                        const name = establishments.find(
                            (e: { id: number }) => e.id === establishmentId
                        )?.nombre;

                        establishmentsWithoutRgrl.push(name || `${establishmentId}`);
                    }
                } catch (error) {
                    enqueueSnackbar(
                        t('alerts.verosimilitudValidation.apiError', {
                            id: establishmentId
                        }),
                        { variant: 'error' }
                    );
                    return;
                }
            }
            setLoadingValidacionRGRL(false);
            if (establishmentsWithoutRgrl.length > 0) {
                const message = establishmentsWithoutRgrl.map((nombre) => `${nombre}`).join('\n');

                dispatch(
                    showAlert({
                        title: t('alerts.verosimilitudValidation.title'),
                        message: t('alerts.verosimilitudValidation.message', {
                            establecimientos: message
                        }),
                        icon: 'error'
                    })
                );
                return;
            }
        }

        dispatch(
            showAlert({
                title: t('alerts.generateVisit.title'),
                message: t('alerts.generateVisit.message'),
                confirmAction: async () => {
                    try {
                        await createVisita(formattedData).unwrap();
                        enqueueSnackbar(t('alerts.generateVisit.success'), { variant: 'success' });
                        navigate('/backoffice/visitas');
                    } catch (error) {
                        enqueueSnackbar(t('alerts.generateVisit.error'), { variant: 'error' });
                    }
                },
                confirmText: t('alerts.generateVisit.confirmText'),
                cancelText: t('alerts.generateVisit.cancelText'),
                icon: 'info'
            })
        );
    };

    const handleCancel = () => {
        dispatch(
            showAlert({
                title: t('alerts.cancelVisit.title'),
                message: t('alerts.cancelVisit.message'),
                confirmAction: () => {
                    handleClearSelection();
                    navigate('../backoffice/visitas');
                },
                confirmText: t('alerts.cancelVisit.confirmText'),
                cancelText: t('alerts.cancelVisit.cancelText'),
                icon: 'info'
            })
        );
    };

    const isNextDisabled =
        !formData.idTipoVisita ||
        !formData.idPreventor ||
        !formData.fechaLimite ||
        !formData.observaciones ||
        !formData.nroContrato ||
        !formData.idOrganismo ||
        !formData.idProvincia ||
        !formData.ciudad;

    return {
        step,
        setStep,
        formData,
        handleChange,
        contracts,
        cities,
        provinces,
        organisms,
        handleContractSelection,
        establishments,
        handleSelectEstablishment,
        handleClearSelection,
        handleSubmit,
        handleCancel,
        isNextDisabled,
        isFetchingContracts,
        loadingValidacionRGRL
    };
}

export default useCreateVisita;
